import persona from "./persona"

import ideLogo from "../assets/img/ide_logo.png"
import lfdiLogo2 from "../assets/img/lfdi_logo2.png"
import bawueLogo from "../assets/img/bawue_logo.png"
import swrLogo from "../assets/img/SWR_logo.jpg"

const selbsteinschaetzungQuestions = [
  {
    persona: persona.Pro,
    question:
      "Datenschutz ist mir sehr wichtig! Dafür bin ich gerne bereit, auf bestimmte Dinge zu verzichten.",
  },
  {
    persona: persona.Teilzeit,
    question:
      "Ich versuche, meine Daten zu schützen, aber auf manche Dienste möchte ich einfach nicht verzichten.",
  },
  {
    persona: persona.Unwissender,
    question:
      "Ich habe mich mit Datenschutz bislang noch nicht beschäftigt.",
  },
  {
    persona: persona.Egalo,
    question:
      "Um Datenschutz mache ich mir keine Sorgen, ich habe ja nichts zu verbergen.",
  },
  {
    persona: persona.Enthusiast,
    question:
      "Ich probiere alle neuen digitalen Produkte und Dienste aus! Datenschutz kommt erst an zweiter Stelle.",
  },
]

const questions = [
  {
    topic: "Messenger",
    question: "Welche Messengerdienste benutzt du?",
    answers: [
      {
        text:
          "Ich nutze den Messenger, der in meinem persönlichen Umfeld am häufigsten genutzt wird.",
        beneficiary: [persona.Unwissender],
      },
      {
        text: "Ich nutze verschiedene Messengerdienste und probiere gerne neue Dienste aus, um immer auf dem neusten technischen Stand zu sein.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text:
          "Ich versuche, möglichst datenschutzfreundliche Dienste wie Signal zu benutzen, aber ich kommuniziere auch über andere Dienste, weil ich bestimmte Kontakte nicht verlieren möchte.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text:
          "Ich nutze ausschließlich datenschutzfreundliche Dienste, über die ich mich vorher genau informiert habe.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Ich nutze den Messenger, der mir die meisten Vorteile bietet. Der Datenschutz ist dabei aber kein entscheidendes Kriterium für mich.",
        beneficiary: [persona.Egalo],
      },
      {
        text:
          "Ich nutze überhaupt keine Messengerdienste, sondern greife lieber auf andere Kommunikationsmittel zurück wie beispielsweise SMS, Telefon, E-Mail etc.",
        beneficiary: [],
      },
    ],
  },
  {
    topic: "Smartphone Kauf",
    question: "Worauf achtest du beim Kauf eines neuen Smartphones?",
    answers: [
      {
        text:
          "Ich entscheide mich immer für die neuesten Spitzenmodelle mit den besten Features.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text: "Ich achte auf das beste Preis-Leistungs-Verhältnis und entscheide hauptsächlich danach, wie mir das Gerät gefällt und wie es sich bedienen lässt.",
        beneficiary: [persona.Egalo, persona.Unwissender],
      },
      {
        text: "Ich entscheide mich nur für Modelle und Hersteller, bei denen ich weiß, dass der Datenschutz geachtet wird und dass ich selbst detaillierte Privatsphäreeinstellungen auf dem Smartphone treffen kann.",
        beneficiary: [persona.Pro],
      },
      {
        text: "Ich achte zwar schon auf die Möglichkeit, datenschutzfreundliche Voreinstellungen zu treffen, aber sie sind nicht das vorrangige Entscheidungskriterium.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text: "Ich nutze kein Smartphone.",
        beneficiary: [],
      },
    ],
  },
  {
    topic: "Mobiles Internet",
    question: "Wie verbindest du Dich mit dem Internet, wenn du unterwegs bist?",
    answers: [
      {
        text:
          "Ich nutze ausschließlich meine mobilen Daten und lehne es aus Datenschutzgründen kategorisch ab, ein öffentliches WLAN zu nutzen.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Sobald ein öffentliches WLAN zur Verfügung steht, greife ich gerne darauf zurück, ist ja auch besser fürs Datenvolumen.",
        beneficiary: [persona.Egalo, persona.Unwissender],
      },
      {
        text:
          "Ich wähle den Weg, der mir den schnellsten Zugang und die beste Datenübertragung ermöglicht.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text: "Ich nutze meistens meine mobilen Daten, aber bei einem vertrauenswürdigen Anbieter nutze ich auch ein öffentliches WLAN.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text: "Wenn ich unterwegs bin, bin ich offline.",
        beneficiary: [],
      },
    ],
  },
  {
    topic: "Suchmaschine",
    question:
      "Welche Suchmaschine nutzt du?",
    answers: [
      {
        text:
          "Ich suche auf Google und kenne eigentlich auch gar keine anderen Suchmaschinen.",
        beneficiary: [persona.Unwissender],
      },
      {
        text:
          "Aus Bequemlichkeit benutze ich Google oder die vorinstallierte Suchmaschine. Von Datenschutzfragen möchte ich mich dabei nicht ausbremsen lassen.",
        beneficiary: [persona.Egalo],
      },
      {
        text:
          "Ich suche nur über datensparsame Alternativen zu Google wie beispielsweise DuckDuckGo oder Startpage.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Ich versuche, alternative Anbieter wie Ecosia und DuckDuckGo häufig zu benutzen, aber oft liefert Google mehr Ergebnisse, und deshalb greife ich hin und wieder auch auf Google zurück.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text:
          "Ich probiere immer verschiedene und neue Anbieter aus und vergleiche gerne die Ergebnisse, weil ich immer über den neusten Stand der Digitalisierung informiert sein möchte, unabhängig vom Datenschutz.",
        beneficiary: [persona.Enthusiast],
      },
    ],
  },
  {
    topic: "Cookies",
    question: "Wie verhältst du dich, wenn du beim Aufrufen einer neuen Webseite nach den Cookie-Einstellungen gefragt wirst?",
    answers: [
      {
        text:
          "Das nervt mich total, deshalb akzeptiere ich sofort alle Cookies, damit das Pop-Up-Fenster endlich verschwindet.",
        beneficiary: [persona.Egalo],
      },
      {
        text:
          "Ich nutze generell einen Browser, der keine Cookies zulässt, oder einen Trackingblocker.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Ich finde den Einsatz von Cookies sinnvoll und sehe darin sogar Vorteile für mich, deshalb stimme ich gerne zu.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text:
          "Ich akzeptiere nur das notwendige Setting.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text:
          "Ich klicke einfach auf Akzeptieren. Ich bin mir gar nicht so sicher, was Cookies eigentlich sind.",
        beneficiary: [persona.Unwissender],
      },
    ],
  },
  {
    topic: "Social Media",
    question:
      "Meldest du dich nach der Nutzung von Sozialen Netzwerken ab?",
    answers: [
      {
        text:
          "Ich logge mich nach jeder Nutzung wieder aus oder nutze gar keine Sozialen Netzwerke aus Datenschutzgründen.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Meistens, wenn ich es nicht vergesse.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text: "Nein, denn so erspare ich mir bewusst die erneute Passworteingabe.",
        beneficiary: [persona.Egalo],
      },
      {
        text:
          "Ich bin eigentlich fast immer online.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text:
          "Darauf habe ich bislang noch nie geachtet.",
        beneficiary: [persona.Unwissender],
      },
      {
        text:
          "Ich nutze aus anderen Gründen keine sozialen Netzwerke.",
        beneficiary: [],
      },
    ],
  },
  {
    topic: "Passwörter/Zugang",
    question: "Wie gehst du bei der Registrierung auf einer neuen Plattform vor?",
    answers: [
      {
        text:
          "Ich wähle den für mich bequemsten Weg und nutze, falls möglich, die Anmeldung über bereits bestehende Konten wie Google oder Facebook.",
        beneficiary: [persona.Egalo],
      },
      {
        text: "Ich verwende immer einen neuen Username und ein sicheres, geschütztes Passwort, das ich professionell über eine spezielle Software oder App erstellen lasse.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Meistens denke ich mir einen neuen Zugang und ein Passwort aus, aber manchmal verwende ich die gleichen Passwörter, um sie nicht zu vergessen.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text: "Ich nehme eigentlich immer das gleiche Passwort.",
        beneficiary: [persona.Unwissender],
      },
      {
        text: "Ich habe eine App, die alle meine Passwörter für mich sammelt und speichert, deshalb denke ich mir meistens ein neues Passwort aus.",
        beneficiary: [persona.Enthusiast],
      },
    ],
  },
  {
    topic: "Organisation & Kalender",
    question: "Wie organisierst du Deinen Terminkalender?",
    answers: [
      {
        text:
          "Eine digitale Lösung würde ich für private Termine nur verwenden, wenn meine Daten lokal auf dem Gerät gespeichert werden und ich diese auch sicher wieder löschen könnte. In der Arbeit achte ich sehr auf Datenschutz und -sicherheit.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Ich benutze eine datenschutzfreundliche Lösung und vertraue dabei auf bekannte Anbieter.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text:
          "Meine Termine verraten doch nichts über mich oder mein Privatleben. Deshalb finde ich Datenschutz hier übertrieben.",
        beneficiary: [persona.Unwissender],
      },
      {
        text:
          "Ich verwende grundsätzlich alle Möglichkeiten und Schnittstellen, die mir die Digitalisierung bietet, und finde es super, wie das meinen Alltag erleichtert.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text:
          "Ich nutze einfach den Kalender auf meinem Smartphone oder auf meinem Arbeitsrechner.",
        beneficiary: [persona.Egalo],
      },
    ],
  },
  {
    topic: "App-Berechtigung",
    question:
      "Du möchtest eine neue App installieren – Befasst du dich vorher mit den Zugriffsrechten?",
    answers: [
      {
        text:
          "Ja. Deshalb kommen bestimmte Apps für mich auch nicht in Frage, wenn diese unverhältnismäßig auf meine Daten zugreifen möchten und ich das nicht unterbinden kann.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Ich versuche, den Datenzugriff im Nachgang über die Einstellungen zu regulieren.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text: "Nein, denn ich wüsste auch gar nicht, wo ich diese Informationen finden könnte.",
        beneficiary: [persona.Unwissender],
      },
      {
        text:
          "Ich weiß zwar, dass viele kostenlose Apps mit meinen Daten bezahlt werden, aber das ist mir egal, solange die App gut ist.",
        beneficiary: [persona.Egalo, persona.Enthusiast],
      },
    ],
  },
  {
    topic: "Fitnesstracker",
    question: "Würdest du einen Fitnesstracker benutzen?",
    answers: [
      {
        text: "Ja, weil mir meine Gesundheit wichtig ist und mir ein Fitnesstracker dabei helfen kann, gesund zu leben.",
        beneficiary: [persona.Egalo, persona.Enthusiast],
      },
      {
        text: "Ich würde aus Datenschutzgründen keinen Fitnesstracker benutzen, bei dem meine Daten weitergegeben werden. Ich würde das nur dann in Betracht ziehen, wenn ich dabei die volle Kontrolle über meine Daten hätte.",
        beneficiary: [persona.Pro],
      },
      {
        text: "Einen Fitnesstracker habe ich nicht, aber ich benutze ab und zu mein Smartphone, um zu wissen, wie viele Schritte ich am Tag gelaufen bin.",
        beneficiary: [persona.Egalo, persona.Unwissender],
      },
      {
        text: "Ich würde mir so ein Gerät anschaffen, wenn ich den Hersteller in punkto Datenverarbeitung und -weitergabe für vertrauenswürdig halten würde.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text:
          "Nein, ich habe keine Fitnesstracker und würde auch keine benutzen wollen.",
        beneficiary: [],
      },
    ],
  },
  {
    topic: "Ortungsdienste",
    question:
      "Deaktivierst du bewusst die Ortungsdienste auf deinem Smartphone?",
    answers: [
      {
        text: "Ich weiß nicht genau, welche Anwendungen auf meine Standortdaten zugreifen und wie ich das regulieren soll.",
        beneficiary: [persona.Unwissender],
      },
      {
        text:
          "Ich gestatte den Zugriff auf meine Standortdaten, weil ich mir davon bessere Dienstleistungen erhoffe.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text:
          "Ja, ich deaktiviere oder reguliere den Zugriff auf meine Daten sehr bewusst und ausnahmslos.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Das kommt auf die Anwendung an. Manche Apps brauchen die Standortdaten und dann genehmige ich den Zugriff. Wahrscheinlich müsste ich es aber regelmäßiger überprüfen.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text: "Meine Standortdaten sind für mich keine wirklich persönlichen Informationen, daher ist es mir egal, wer darauf Zugriff hat.",
        beneficiary: [persona.Egalo],
      },
    ],
  },
  {
    topic: "Sprachassistenten",
    question:
      "Würdest du einen digitalen Sprachassistenten wie Alexa benutzen?",
    answers: [
      {
        text:
          "Ja, auf jeden Fall. Ich finde die neue Technik super!",
        beneficiary: [persona.Unwissender, persona.Enthusiast],
      },
      {
        text:
          "Ich würde mir nicht extra einen anschaffen, aber ich fände die Technik schon praktisch, wenn sie beispielsweise in meinem neuen Auto verbaut wäre.",
        beneficiary: [persona.Egalo],
      },
      {
        text:
          "Nein, denn ich möchte die Aufzeichnung und Weitergabe meiner Daten auf jeden Fall vermeiden.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Manche Anwendungen finde ich schon praktisch, aber ich versuche, mir immer bewusst zu machen, welche Daten ich dabei von mir preisgeben könnte.",
        beneficiary: [persona.Teilzeit],
      },
    ],
  },
  {
    topic: "Zahlungsmethoden",
    question:
      "Welche Zahlungsmethoden und -anbieter bevorzugst du?",
    answers: [
      {
        text:
          "Bargeld finde ich unpraktisch und etwas altmodisch. Ich nutze sowohl beim Online-Shopping als auch im Geschäft gerne die breite Palette elektronischer Bezahlsysteme, weil sie schnell und unkompliziert funktionieren.",
        beneficiary: [persona.Enthusiast, persona.Unwissender],
      },
      {
        text:
          "Ich entscheide mich von Fall zu Fall für die jeweils bequemste Variante. Manchmal greife ich auf Bargeld zurück, manchmal nutze ich ein elektronisches Bezahlsystem.",
        beneficiary: [persona.Egalo, persona.Unwissender],
      },
      {
        text:
          "Auch wenn mich der Gedanke stört, dass meine Kaufinformationen gesammelt werden, greife ich auf ein elektronisches Bezahlsystem zurück, wenn es sich nicht vermeiden lässt.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text:
          "Ich bevorzuge Bargeldeinkäufe. Lässt es sich gar nicht vermeiden, versuche ich auf elektronische Zahlungsmethoden zurückzugreifen, die von meiner Bank direkt zur Verfügung gestellt werden. In jedem Fall überprüfe ich, ob meine Daten weitergereicht werden könnten, und suche ggf. nach Alternativen.",
        beneficiary: [persona.Pro],
      },
    ],
  },
  {
    topic: "Online-Shopping",
    question:
      "Wie verhältst du dich beim Online-Shopping?",
    answers: [
      {
        text:
          "Ich nutze den für mich einfachsten und schnellsten Weg. Meistens ist das Amazon.",
        beneficiary: [persona.Egalo, persona.Unwissender],
      },
      {
        text:
          "Ich versuche, das Online-Shopping zu vermeiden, damit nicht jeder Einkauf nachverfolgt werden kann. Außerdem ist das ökologischer.",
        beneficiary: [persona.Pro], 
      },
      {
        text:
          "Ich kaufe nur noch über das Internet und sehe auch keine Alternative für die Zukunft.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text:
          "Ich kaufe bevorzugt bei ganz bestimmten Unternehmen ein, denen ich mein Vertrauen schenke. Wenn es sich vermeiden lässt, verzichte ich auf den Kauf bei Großkonzernen, die meine Daten sammeln.",
        beneficiary: [persona.Teilzeit],
      },
    ],
  },
  {
    topic: "Online-Dating",
    question: "Würdest du eine Dating-App benutzen?",
    answers: [
      {
        text:
          "Wenn ich auf der Partnersuche wäre, würde ich die bekanntesten Dienste nutzen und alle Informationen preisgeben, um die besten Matches für mich finden zu können.",
        beneficiary: [persona.Enthusiast],
      },
      {
        text:
          "Ich verzichte auf eine Dating-App aus Datenschutzgründen.",
        beneficiary: [persona.Pro],
      },
      {
        text:
          "Ich würde sie benutzen, aber versuchen, möglichst wenig Informationen preiszugeben und auch nur für kurze Zeit.",
        beneficiary: [persona.Teilzeit],
      },
      {
        text:
          "Ja, denn bei einer Dating-App, auf der man nur drei Bilder hochlädt, gibt man nicht zu viele Informationen über sich preis.",
        beneficiary: [persona.Unwissender],
      },
      {
        text:
          "Ich würde grundsätzlich keine Dating-App benutzen.",
        beneficiary: [],
      },
    ],
  },
]

const bewertungTexte = [
  {
    persona: persona.Pro,
    text:
      "Du kennst dich mit Datenschutz schon sehr gut aus und verstehst, dass du über dein Surfverhalten und deine Gerätenutzung bestimmen kannst, wer Zugriff auf deine persönlichen Daten erhält. Den damit stellenweise verbundenen Verzicht nimmst du in Kauf, denn so bewahrst du dir deine digitale Souveränität.",
  },
  {
    persona: persona.Teilzeit,
    text:
      "Dir ist bewusst, dass Datenschutz ein wichtiges Thema ist, und du verstehst, dass du durch dein Verhalten aktiv steuern kannst, wer Zugriff auf deine persönlichen Daten erhält. Wenn du aber eine bestimmte Anwendung unbedingt nutzen willst, zum Beispiel, weil sie dir den Alltag erleichtert, bist du auch bereit, deine Daten als Währung zu akzeptieren.",
  },
  {
    persona: persona.Unwissender,
    text:
      "Das Internet und digitale Anwendungen bieten dir viele Vorteile und machen dein Leben einfach bequemer. Das möchtest du uneingeschränkt nutzen und dabei ist dir egal, ob jemand deine Bequemlichkeit ausnutzen könnte. Du hast zwar schon einmal gehört, dass Datenschutz ein wichtiges Thema sein soll, weißt aber eigentlich gar nicht so recht, um was es da genau gehen soll.",
  },
  {
    persona: persona.Egalo,
    text:
      "Wenn eine Anwendung deinen Alltag erleichtern kann oder einfach Spaß macht, nutzt du sie. Die Diskussion über Datenschutz kannst du dabei nicht nachvollziehen. Du hast zwar mitbekommen, dass Digitalkonzerne viele Daten sammeln und damit Geld verdienen, du verstehst aber nicht so ganz, warum das ein Problem sein soll.",
  },
  {
    persona: persona.Enthusiast,
    text:
      "Du bist gegenüber technischer Innovation sehr aufgeschlossen und probierst gerne Neues aus. Um neue Geräte und Anwendungen vollumfänglich nutzen zu können, bist du auch bereit, deine persönlichen Daten als Währung zu verwenden.",
  },
]

const tippTexte = [
  {
    persona: persona.Pro,
    text:
      "Herzlichen Glückwunsch! Du bist beim Thema Datenschutz sehr engagiert und kennst dich gut aus. Wir empfehlen dir - Bleib‘ am Ball! Vielleicht interessieren dich die folgenden Angebote, die dich in Sachen Datenschutz immer auf dem Laufenden halten.",  
  },
  {
    persona: persona.Teilzeit,
    text:
      "Gratulation! Das Thema Datenschutz liegt dir am Herzen. Dennoch fühlst du dich manchmal hin- und hergerissen, insbesondere, wenn du dich trotz einiger Zweifel ab und zu auch gegen den Datenschutz entscheidest. Vielleicht helfen dir die folgenden Tipps und Informationen dabei, dich im Umgang mit deinen Daten und dem Schutz deiner Privatsphäre noch sicherer fühlen zu können. Damit kannst du auch vor deinen Freundinnen und Freunden und deinem Umfeld den Mehrwert des Datenschutzes deutlicher erkennen und benennen.",
  },
  {
    persona: persona.Unwissender,
    text:
      "Das Thema Datenschutz ist in deinen Augen sehr komplex und du hast manchmal das Gefühl, dir fehlen vielleicht die richtigen Informationen und das Wissen, um einen echten Zugang zu diesem Thema zu finden? Wir empfehlen dir daher die folgende Einführung.",
  },
  {
    persona: persona.Egalo,
    text:
      "Du findest, das Thema Datenschutz wird unnötig hoch bewertet, und du zweifelst sogar manchmal am Sinn des Datenschutzes? Wir empfehlen dir daher die folgenden Berichte und Informationen, um einen neuen Blick auf die Bedeutung des Datenschutzes zu werfen.",
  },
  {
    persona: persona.Enthusiast,
    text:
      "Du möchtest deine Begeisterung für die Digitalisierung nicht durch Datenschutz schmälern lassen. Du befürchtest, dass Datenschutz eher ein Innovationshindernis ist? Wir empfehlen dir daher die folgenden Links, um einen neuen Blick auf das Thema Datenschutz zu werfen.",
  },
]

const tippLinks = [
  {
    persona: persona.Pro,
    text1: "TIPP 1 ) Auch wenn du dazu bereit bist, muss Datenschutz nicht unbedingt Verzicht bedeuten. Für viele digitale Anwendungen, Tools und Services gibt es auch Alternativen, die ethische Facetten berücksichtigen. Sicherlich sind dir viele bereits bekannt, aber vielleicht ist dir auch noch die eine oder andere Alternative entgangen. Eine umfangreiche Sammlung vom datenschutzfreundlichen Web-Browser bis hin zum fairen Smartphone findest du unter ",
    text2: <a href="https://ethical.net/resources/" target="_blank">ethical.net/resources/ (EN).</a>,  
    text3: "TIPP 2 ) Die neuesten Meldungen, Publikationen und Projekte zum Thema Datenschutz und Digitale Ethik kannst du auf dem 'Mastodon'-Account des Instituts für Digitale Ethik verfolgen. Aufrufbar unter ",
    text4: <a href="https://bawü.social/@DigitaleEthik" target="_blank">bawü.social/@DigitaleEthik (DE).</a>,
  },
  {
    persona: persona.Teilzeit,
    text1: "TIPP 1 ) Eine einfache Möglichkeit selbst in Sachen Datenschutz tätig zu werden bietet das 'Digital Detox Kit'. Umfangreiche Tipps und praktische Anleitungen zu Fragen wie du deine Smartphone-Daten kontrollierst oder dich von unliebsamen Standarteinstellungen befreist findest du unter ",
    text2: <a href="https://datadetoxkit.org/de/home/" target="_blank">datadetoxkit.org/de/home/ (DE).</a>,  
    text3: "TIPP 2 ) Datenschutz muss nicht unbedingt Verzicht bedeuten. Für viele digitale Anwendungen, Tools und Services gibt es auch Alternativen, die ethische Facetten berücksichtigen. Eine umfangreiche Sammlung vom datenschutzfreundlichen Web-Browser bis hin zum fairen Smartphone findest du unter ",
    text4: <a href="https://ethical.net/resources/" target="_blank">ethical.net/resources/ (EN).</a>,  
  },
  {
    persona: persona.Unwissender,
    text1: "TIPP 1 ) Das Projekt ‘Do Not Track’ bietet dir einen unkomplizierten Einstieg ins Thema und zeigt dir mit Hilfe von 7 kurzen und interaktiven Videoclips, warum Datenschutz auch für dich wichtig sein kann. Aufrufbar unter ",
    text2: <a href="https://donottrack-doc.com/de/intro/" target="_blank">donottrack-doc.com/de/intro/ (DE/EN).</a>,  
    text3: "TIPP 2 ) Das Internet und die Digitalisierung schaffen unglaublich viele neue Möglichkeiten. In welchen Situationen es dabei trotzdem wichtig ist aufmerksam zu sein und sich und andere zu schützen, zeigen die '10 Gebote der Digitalen Ethik' anhand von konkreten Beispielen unter ",
    text4: <a href="https://www.hdm-stuttgart.de/digitale-ethik/lehre/10_gebote" target="_blank">www.hdm-stuttgart.de/digitale-ethik/lehre/10_gebote (DE).</a>,
  },
  {
    persona: persona.Egalo,
    text1: "TIPP 1 ) Das Projekt ‘Do Not Track’ bietet dir einen unkomplizierten Einstieg ins Thema und zeigt dir mit Hilfe von 7 kurzen und interaktiven Videoclips, warum Datenschutz auch für dich wichtig sein kann. Aufrufbar unter ",
    text2: <a href="https://donottrack-doc.com/de/intro/" target="_blank">donottrack-doc.com/de/intro/ (DE/EN).</a>,  
    text3: "TIPP 2 ) Ein guter Einstieg um aktiv tätig zu werden bietet der 'Digital Safety Compass' des Instituts für Digitale Ethik. Praktische Tipps zur digitalen Selbstverteidigung im Drehscheibenformat findest du unter ",
    text4: <a href="https://www.hdm-stuttgart.de/digitale-ethik/lehre/digital_safety_compass" target="_blank">www.hdm-stuttgart.de/digitale-ethik/lehre/digital_safety_compass (DE).</a>,
  },
  {
    persona: persona.Enthusiast,
    text1: "TIPP 1 ) Das Internet und die Digitalisierung schaffen unglaublich viele neue Möglichkeiten. In welchen Situationen es dabei trotzdem wichtig ist aufmerksam zu sein und sich und andere zu schützen, zeigen die '10 Gebote der Digitalen Ethik' anhand von konkreten Beispielen unter ",
    text2: <a href="https://www.hdm-stuttgart.de/digitale-ethik/lehre/10_gebote" target="_blank">www.hdm-stuttgart.de/digitale-ethik/lehre/10_gebote (DE).</a>,  
    text3: "TIPP 2 ) Datenschutz muss nicht unbedingt Verzicht bedeuten. Für viele digitale Anwendungen, Tools und Services gibt es auch Alternativen, die ethische Facetten berücksichtigen. Eine umfangreiche Sammlung vom datenschutzfreundlichen Web-Browser bis hin zum fairen Smartphone findest du unter ",
    text4: <a href="https://ethical.net/resources/" target="_blank">ethical.net/resources/ (EN).</a>,
  },
]

const infobox = {
  buttonText: "Info",
  heading: "Info",
  bodyHeadline1: "Wie funktioniert der Privat-o-Mat?",
  bodyContent1: "Mit dem Privat-o-Mat möchten wir dir helfen, deine Einstellung und dein persönliches Verhalten zum Thema Datenschutz zu reflektieren und besser zu verstehen, damit du im digitalen Alltag ganz bewusst eigene Entscheidungen treffen kannst. Dazu stellen wir dir 15 Fragen aus verschiedenen Bereichen des digitalen Alltags und stellen dir jeweils verschiedene Antwortmöglichkeiten zur Verfügung. Aus diesen Antwortmöglichkeiten wählst du jeweils diejenige aus, die am ehesten deinem persönlichen Verhalten entsprechen würde. Wenn du alle Fragen beantwortet hast, zeigt dir der Privat-o-Mat deinen persönlichen Datenschutztyp und gibt dir ein paar Tipps, wie du noch besser durch den digitalen Alltag kommst.",
  bodyContent1_2: "Klicke auf „Start“, um den Privat-o-Mat zu starten. Wir wünschen dir viel Spaß!",
  headingBarriere: "Barrierefreiheit",
  bodyBarriere1: "Der Privat-o-Mat lässt sich ohne Maus unter Nutzung der Tastatur bedienen. Die Tastenbelegung lautet dabei wie folgt. ",
  H: "'H' = Menüpunkt 'Home', ",
  I: "'I' = Menüpunkt 'Info', ", 
  L: "'L' = Wechsel der Sprache, ",
  P: "'P' = Menüpunkt 'Impressum, Lizenzen & Datenschutz', ",
  Esc: "'Esc' = Fenster schließen, ",
  Enter: "'Enter' = Eingabe / Start / Weiter, ",
  RechterPfeil: "'Rechte Pfeiltaste' = Start / Weiter, ",
  LinkerPfeil: "'Linke Pfeiltaste' = Zurück, ",
  Zahlen: "'Tasten 1-6' = Auswahl der Antwortmöglichkeiten (von links oben nach links unten, weiterführend von rechts oben nach rechts unten) ",
  bodyHeadline2: "Was passiert mit deinen Daten?",
  bodyContent2: "Deine Angaben werden ausschließlich für die Auswertung deines Datenschutztyps genutzt, die dir am Ende des Prozesses angezeigt wird. Der Privat-o-Mat speichert keine deiner Angaben und sammelt darüber hinaus keine weiteren Nutzerdaten.",
  bodyHeadline3: "Wer steckt hinter dem Privat-o-Mat?",
  bodyContent3: "Der Privat-o-Mat ist ein Kooperationsprojekt zwischen dem Institut für Digitale Ethik (IDE) an der Hochschule der Medien Stuttgart (zuständige Mitarbeiterinnen und Mitarbeiter siehe Impressum) und dem Landesbeauftragten für Datenschutz und Informationsfreiheit Baden-Württemberg (zuständige Mitarbeiterin Clarissa Henning). Darüber hinaus unterstützt der Südwestrundfunk (SWR) das Projekt als Medienpartner.",
  bodyLogo1: <a id="ideLink" href="https://www.hdm-stuttgart.de/digitale-ethik" target="_blank">
  <img src={ideLogo} alt="Institut fuer Digitale Ethik Logo" height="90em" id="ideLogo" />
  </a>,
  bodyLogo2: <a id="lfdiLogo2" href="https://www.baden-wuerttemberg.datenschutz.de/" target="_blank">
  <img src={lfdiLogo2} alt="LfDI Logo" height="90em" id="lfdiLogo2" />
  </a>,
  bodyLogo3: <a id="bawueLink" href="https://mwk.baden-wuerttemberg.de/de/startseite/" target="_blank">
  <img src={bawueLogo} alt="Bawue Logo" height="90em" id="bawueLogo" />
  </a>,
  bodyLogo4: <a id="swrLink" href="https://SWR.de/medienstark" target="_blank">
  <img src={swrLogo} alt="SWR Logo" height="90em" id="swrLogo2" />
  </a>,
  bodyTab: <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>, 
  
  headingImpressum: "Impressum, Lizenzhinweise Dritter & Datenschutz",
  headingLizenz: "Lizenzhinweise Dritter",
  headingDatenschutz: "Datenschutz",
  bodyDatenschutz: "Für diese Webseite gelten die Datenschutzbestimmungen der Hochschule der Medien Stuttgart.",
  bodyLizenz: "Diese Webseite enthält ggf. Material von Drittanbietern, welches unter den im folgenden aufgeführten Bestimmungen lizenziert ist.",
  bodyLizenzLink: <a href="https://www.hdm-stuttgart.de/digitale-ethik" target="_blank">Download Lizenzhinweise_Dritter.txt</a>,
  bodyHeadline4: "Impressum",
  bodyContent4:"Der Privat-o-Mat ist ein Produkt des Instituts für Digitale Ethik der Hochschule der Medien Stuttgart. Alle Inhalte wurden von einem Redaktionsteam aus Studierenden der Hochschule der Medien Stuttgart und Mitarbeiterinnen und Mitarbeitern sowie den Verantwortlichen des Instituts für Digitale Ethik entwickelt.",
  bodyHeadline5: "Diensteanbieter gemäß § 5 Telemediengesetz (TMG)",
  bodyHeadline6: "Verantwortlich gemäß § 18 Medienstaatsvertrag (MStV)",
  bodyHeadline7: "Inhalt und Redaktion",
  bodyHeadline8: "Umsetzung, Gestaltungskonzept, Design & Illustration",
  bodyHeadline9: "Programmierung",
  bodyHeadline10: "Nach einer Idee von",
  rechtsPerson: "Die Hochschule der Medien Stuttgart ist eine Körperschaft des öffentlichen Rechts. Sie wird durch den Rektor Professor Dr. Alexander W. Roos gesetzlich vertreten.",
  leitungsgremium: "Leitungsgremium des Instituts für Digitale Ethik (Anschrift s.o.)",
  studierende: "Studierende der Hochschule der Medien",
  mitarbeiterund: "Mitarbeiterinnen und Mitarbeiter und Verantwortliche des Instituts für Digitale Ethik",
  withthehelpof: "Mit freundlicher Unterstützung des Landesbeauftragten für Datenschutz und Informationsfreiheit Baden-Württemberg.",
  
  weiteres: "Wenn du Lust hast, noch mehr zum Thema Datenschutz zu lernen, klicke nun auf WEITER, um zu sehen, welche Antworten für welchen Datenschutztyp stehen. ",
  weiteres2: "Wenn du mit dem Privat-o-Mat noch einmal von vorne anfangen möchtest, klicke einfach im Menü auf HOME, um eine neue Session zu starten.",
  bodyLink1:<a href="https://www.hdm-stuttgart.de/digitale-ethik" target="_blank">Das Institut für Digitale Ethik (IDE)</a>,
}


const template = {
  selbsteinschaetzungQuestions: selbsteinschaetzungQuestions,
  questions: questions,
  bewertungTexte: bewertungTexte,
  tippTexte: tippTexte,
  tippLinks: tippLinks,
  infobox: infobox,
}

export default template
