import React from "react"
import Modal from "react-bootstrap/Modal"

import template from "../logic/template"
import i18n from "../logic/translation"


function Infobox(props) {
  const [show, setShow] = React.useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      {props.type === "button" && (
        <button
          type="button"
          className="btn btn-light btn-md border border-dark text-uppercase"
          onClick={handleShow}
        >
          {i18n.t(template.infobox.buttonText)}
        </button>
      )}

      {props.type === "navLink" && (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="nav-link" id="infoButton" href="#" onClick={handleShow}>
            {i18n.t("Info")}
          </a>
        </>
      )}

      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{i18n.t(template.infobox.heading)}</Modal.Title>
        </Modal.Header>
        <Modal.Body><h4>{i18n.t(template.infobox.bodyHeadline1)}</h4><br></br>{i18n.t(template.infobox.bodyContent1)}<br></br><br></br>{i18n.t(template.infobox.bodyContent1_2)}<br></br><br></br><br></br>
        <h4>{i18n.t(template.infobox.headingBarriere)}</h4><br></br>{i18n.t(template.infobox.bodyBarriere1)}<br></br><br></br>
        {i18n.t(template.infobox.H)}<br></br>
        {i18n.t(template.infobox.I)}<br></br>
        {i18n.t(template.infobox.L)}<br></br>
        {i18n.t(template.infobox.P)}<br></br>
        {i18n.t(template.infobox.Esc)}<br></br>
        {i18n.t(template.infobox.Enter)}<br></br>
        {i18n.t(template.infobox.RechterPfeil)}<br></br>
        {i18n.t(template.infobox.LinkerPfeil)}<br></br>
        {i18n.t(template.infobox.Zahlen)}
        <br></br><br></br><br></br>
                    
                    <h4>{i18n.t(template.infobox.bodyHeadline2)}</h4><br></br>{i18n.t(template.infobox.bodyContent2)}<br></br><br></br><br></br><h4>{i18n.t(template.infobox.bodyHeadline3)}</h4><br></br>
                    {i18n.t(template.infobox.bodyContent3)}<br></br><br></br><br></br>{template.infobox.bodyLogo1}{template.infobox.bodyTab}{template.infobox.bodyLogo2}{template.infobox.bodyLogo3}{template.infobox.bodyTab}{template.infobox.bodyLogo4}<br></br><br></br>
                    <br></br></Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light btn-md border border-dark text-uppercase"
            onClick={handleClose}
          >
            {i18n.t("Schliessen")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Infobox
