import React from "react"

import Infobox from "./Infobox"
import Impressumbox from "./Impressumbox"
import i18n from "../logic/translation"

import logo from "../assets/img/logo.svg"
import ideLogo from "../assets/img/ide_logo.png"
import lfdiLogo from "../assets/img/lfdi_logo.png"
import swrLogo from "../assets/img/SWR_logo.jpg"

function Navigation(props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <span id="navLogo">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={(e) => props.onClick(e)}>
          <img src={logo} alt="Privat-o-Mat" height="35px" id="resetLinkImage" />
        </a>
      </span>
      <button
        className="navbar-toggler"
        type="button"
        onClick={() => {
          let theText = document.querySelectorAll("#navbarText")
          for (let item of theText) {
            item.classList.toggle("show")
          }
        }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarText">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="nav-link" href="#" onClick={(e) => props.onClick(e)}>
              <span id="resetLink">{i18n.t("Home")}</span>
            </a>
          </li>
          <li className="nav-item">
            <Infobox type="navLink" id="infoButton"/>
          </li>
          {/*<li className="nav-item text-uppercase">
            // eslint-disable-next-line jsx-a11y/anchor-is-valid 
            <a
              className="nav-link"
              href="https://www.hdm-stuttgart.de/digitale-ethik/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              {i18n.t("Impressum")}
            </a>
          </li>
           className="nav-item text-uppercase">
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className="nav-link"
              href="https://www.hdm-stuttgart.de/digitale-ethik/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              {i18n.t("Datenschutz")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href={"lizenzhinweise_dritter.txt"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {i18n.t("Lizenzhinweise Dritter")}
            </a>
          </li>*/}
          <li className="nav-item">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a id="languageToggle" className="nav-link" href="#" onClick={(e) => props.onClick(e)}>
              <span id="languageToggle" className={!props.isLanguageEn ? "font-weight-bold" : ""}>
                DE
              </span>
              /
              <span id="languageToggle" className={props.isLanguageEn ? "font-weight-bold" : ""}>
                EN
              </span>
            </a>
          </li>
          <li className="nav-item">
            <Impressumbox type="navLink" id="impressumButton"/>
          </li>
          <li className="nav-item">
            <a id="ideLinkNav" className="nav-link" href="https://www.hdm-stuttgart.de/digitale-ethik" target="_blank">
            <img src={ideLogo} alt="Institut fuer Digitale Ethik Logo" id="ideLogoNav" />
            </a>
          </li>
          
          <li className="nav-item">
            <a id="ldfiLink" className="nav-link" href="https://www.baden-wuerttemberg.datenschutz.de/" target="_blank">
            <img src={lfdiLogo} alt="LfDI Logo" id="lfdiLogo" />
            </a>
          </li>
          <li className="nav-item">
            <a id="swrLink" className="nav-link" href="https://SWR.de/medienstark" target="_blank">
            <img src={swrLogo} alt="SWR Logo" id="swrLogo" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navigation
