import React from "react"
import Carousel from "react-bootstrap/Carousel"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import useKeyboardShortcut from "use-keyboard-shortcut"

import Navigation from "./components/Navigation"
import Infobox from "./components/Infobox"
import Impressumbox from "./components/Impressumbox"
import Comic from "./components/Comic"
import {
  ermittleName,
  ermittleClaim,
  ermittleAvatar,
  ermittleAvatarBg,
  ermittleFrageBg,
} from "./logic/persona.js"
import template from "./logic/template"
import persona from "./logic/persona.js"
import i18n from "./logic/translation.js"
import Footer from "./components/Footer"
import "./App.css"

import ergebnis3img from "./assets/img/ergebnis3.png"

function App() {
  //todo: Reihenfolge template.questions zufällig umsortieren
  //https://stackfame.com/5-ways-to-shuffle-an-array-using-moder-javascript-es6

  const [pageIndex, setPageIndex] = React.useState(0)
  const [personaSelbsteinschaetzung, setpersonaSelbsteinschaetzung] = React.useState(-1)
  const [personaHistory, setPersonaHistory] = React.useState(
    Array.from({ length: template.questions.length }, () => -1)
  )
  const [startseiteIndex, setStartseiteIndex] = React.useState(0)
  const [isAuswertung, setIsAuswertung] = React.useState(false)
  const [auswertungSelectedPersona, setAuswertungSelectedPersona] = React.useState(-1)
  const [isLanguageEn, setIsLanguageEn] = React.useState(false)

  /*
  //zum Testen an bestimmter Stelle
  const [pageIndex, setPageIndex] = React.useState(19)
  const [personaSelbsteinschaetzung, setpersonaSelbsteinschaetzung] = React.useState(4)
  // prettier-ignore
  const [personaHistory, setPersonaHistory] = React.useState([ 1, 1, 0, 1, 0, 0, 1, 2, 3, 1, 0, 1, 2, 3, 1, 0,])
  const [startseiteIndex, setStartseiteIndex] = React.useState(0)
  const [isAuswertung, setIsAuswertung] = React.useState(true)
  const [auswertungSelectedPersona, setAuswertungSelectedPersona] = React.useState(-1)
*/

  React.useEffect(() => {
    /* Hintergrundbild */

    if (pageIndex === 0) {
      document.body.style.backgroundImage = "url(" + ermittleAvatarBg(startseiteIndex) + ")"
    } else if (pageIndex === 1) {
      document.body.style.backgroundImage = "none"
    } else if (pageIndex >= template.questions.length + 2) {
      if (pageIndex === template.questions.length + 2) {
        //Berechnen
        var personaResultsCounter = [0, 0, 0, 0, 0]
        personaHistory.forEach(function (historyItem, idx) {
          if (historyItem !== -1) {
            var beneficiary = template.questions[idx].answers[historyItem].beneficiary
            beneficiary.forEach((element) => {
              personaResultsCounter[element] += 1
            })
          }
        })
        let personaRanking = Array.from(Array(personaResultsCounter.length).keys()).sort(
          (a, b) => parseInt(personaResultsCounter[b]) - parseInt(personaResultsCounter[a])
        )
        let personaRankingBest = personaRanking[0]

        document.body.style.backgroundImage = "url(" + ermittleAvatarBg(personaRankingBest) + ")"
      } else {
        document.body.style.backgroundImage = "none"
      }
    } else if (pageIndex >= 2) {
      document.body.style.backgroundImage = "url(" + ermittleAvatarBg(persona.Teilzeit) + ")"
    }
  })

  //Tastatur

    //prettier-ignore
    useKeyboardShortcut(["I"], () => {
      var element = document.getElementById("infoButton")
      if (element) {
        document.getElementById("infoButton").click()
      }
    }, {})

        //prettier-ignore
        useKeyboardShortcut(["P"], () => {
          var element = document.getElementById("impressumButton")
          if (element) {
            document.getElementById("impressumButton").click()
          }
        }, {})

    //prettier-ignore
    useKeyboardShortcut(["H"], () => {
      var element = document.getElementById("resetLink")
      if (element) {
        document.getElementById("resetLink").click()
      }
    }, {})

  //prettier-ignore
  useKeyboardShortcut(["ArrowLeft"], () => {
    var element = document.getElementById("footerPrev")
    if (element) {
      document.getElementById("footerPrev").click()
    }
  }, {})

  //prettier-ignore
  useKeyboardShortcut(["ArrowRight"], () => {
    if (pageIndex === 0) {
      document.getElementById("startseiteButtonStart").click()
    } else {
      var element = document.getElementById("footerPrev")
      if (element) {
        document.getElementById("footerNext").click()
      }
    }
  }, {})

  //prettier-ignore
  useKeyboardShortcut(["Enter"], () => {
    if (pageIndex === 0) {
      document.getElementById("startseiteButtonStart").click()
    } else {
      var element = document.getElementById("footerPrev")
      if (element) {
        document.getElementById("footerNext").click()
      }
    }
  }, {})

  //prettier-ignore
  useKeyboardShortcut(["L"], (e) => {
    setIsLanguageEn(!isLanguageEn)
    if (isLanguageEn) {
      i18n.changeLanguage("de")
    } else {
      i18n.changeLanguage("en")
    }
  }, {})

  //prettier-ignore
  useKeyboardShortcut(["1"], (e) => {
    handleKeypress1to5(Object.entries(e)[0][0] - 1)
  }, {})
  //prettier-ignore
  useKeyboardShortcut(["2"], (e) => {
    handleKeypress1to5(Object.entries(e)[0][0] - 1)
  }, {})
  //prettier-ignore
  useKeyboardShortcut(["3"], (e) => {
    handleKeypress1to5(Object.entries(e)[0][0] - 1)
  }, {})
  //prettier-ignore
  useKeyboardShortcut(["4"], (e) => {
    handleKeypress1to5(Object.entries(e)[0][0] - 1)
  }, {})
  //prettier-ignore
  useKeyboardShortcut(["5"], (e) => {
    handleKeypress1to5(Object.entries(e)[0][0] - 1)
  }, {})
    //prettier-ignore
    useKeyboardShortcut(["6"], (e) => {
      handleKeypress1to5(Object.entries(e)[0][0] - 1)
    }, {})

  const handleKeypress1to5 = (idx) => {
    let check = document.querySelector("div.active.carousel-item #item_" + idx)
    if (!check) {
      return
    }

    if (isAuswertung === false) {
      if (pageIndex === 1) {
        //Selbsteinschätzung
        setpersonaSelbsteinschaetzung(idx)
      } else if (pageIndex > 1 && pageIndex < template.questions.length + 2) {
        //Fragen
        let newPersonaHistory = [...personaHistory]
        newPersonaHistory[pageIndex - 2] = idx
        setPersonaHistory(newPersonaHistory)
      }
    }
  }

  //Carousel
  const handleSelect = (selectedIndex, e) => {
    setPageIndex(selectedIndex)
  }
  const handleSelectStartseite = (selectedIndex, e) => {
    if (pageIndex === 0) {
      setStartseiteIndex(selectedIndex)
    }
  }

  const handleClick = (e) => {
    //console.log(pageIndex, template.questions.length + 1, e.target.id)

    if (e.target.id === "footerPrev" || e.target.id === "footerNext") {
      window.scrollTo(0, 0)
      document.activeElement.blur()
      setAuswertungSelectedPersona(-1)

      if (e.target.id === "footerPrev") {
        if (pageIndex === 0) {
          setPageIndex(template.questions.length + 4)
        } else if (pageIndex === 2 && isAuswertung === true) {
          setPageIndex(template.questions.length + 4)
        } else {
          setPageIndex(pageIndex - 1)
        }
      } else if (e.target.id === "footerNext") {
        if (pageIndex === template.questions.length + 4) {
          //Ergebnis #3 => Auswertung
          setPageIndex(2)
          setIsAuswertung(true)
        } else {
          setPageIndex(pageIndex + 1)
        }
      }
    } else if (e.target.id === "languageToggle") {
      setIsLanguageEn(!isLanguageEn)
      if (isLanguageEn) {
        i18n.changeLanguage("de")
      } else {
        i18n.changeLanguage("en")
      }
    } else if (e.target.id === "resetLink" || e.target.id === "resetLinkImage") {
      if (window.confirm(i18n.t("Möchtest du deine bisherigen Eingaben löschen und mit dem Privat-o-Mat von vorne beginnen?"))) {
        window.location.reload()
      }
    } else {
      if (pageIndex === 0) {
        //Startseite
        if (e.target.id === "startseiteButtonStart") {
          setPageIndex(pageIndex + 1)
        }
      } else if (pageIndex === 1) {
        //Selbsteinschaetzung
        var itemPosition = parseInt(e.target.parentNode.id.split("_")[1])
        if (isNaN(itemPosition)) {
          //sprechblaseBild geklickt
          itemPosition = parseInt(e.target.parentNode.parentNode.id.split("_")[1])
        }
        setpersonaSelbsteinschaetzung(itemPosition)
      } else if (pageIndex > 1 && pageIndex < template.questions.length + 2) {
        //Fragen
        if (isAuswertung === false) {
          itemPosition = parseInt(e.target.parentNode.id.split("_")[1])

          let newPersonaHistory = [...personaHistory]
          newPersonaHistory[pageIndex - 2] = itemPosition
          setPersonaHistory(newPersonaHistory)
        } else if (isAuswertung === true) {
          if (e.target.parentNode.parentNode.parentNode.id) {
            const itemPosition = parseInt(
              e.target.parentNode.parentNode.parentNode.id.split("_")[1]
            )
            setAuswertungSelectedPersona(itemPosition)
          }
        }
      }
    }
  }

  return (
    <>
      <div id="wrapper">
        <Navigation isLanguageEn={isLanguageEn} onClick={(e) => handleClick(e)} />
        <div className="container-flex" id="content-wrapper">
          <Carousel
            activeIndex={pageIndex}
            onSelect={handleSelect}
            wrap={false}
            interval={null}
            indicators={false}
            fade={true}
            keyboard={false}
            touch={false}
            controls={false}
          >
            {/* Startseite */}
            <Carousel.Item>
              <div className="row pt-5" id="rowStartseite">
                <div className="col-md-6 navText">
                  <p id="headline" style={{ marginLeft: 10 }}>
                    {i18n.t("Hallo und herzlich willkommen beim Privat-o-Mat.")}
                  </p>
                  <hr style={{ marginLeft: 0 }} />
                  <p style={{ marginLeft: 10 }}>
                    {i18n.t("Entscheidungen zum Thema Datenschutz und zum Schutz der eigenen Privatsphäre sind Teil des digitalen Alltags geworden. Deshalb helfen wir dir dabei, deine Einstellung und dein persönliches Verhalten dazu zu reflektieren und besser zu verstehen.")}
                  </p>                  <p style={{ marginLeft: 10 }} id="barriereIntro">{i18n.t("Für Informationen zur barrierefreien Nutzung, klicke auf 'Info' oder drücke die Taste 'I' auf deiner Tastatur.")}</p>

                  <p style={{ marginLeft: 10 }}>{i18n.t("Viel Spaß dabei!")}</p>

                  <div className="row justify-content-center mt-4 pb-3">
                 
                    <Infobox type="button"/>
                    <button
                      type="button"
                      className="btn btn-light btn-md border border-dark ml-6 text-uppercase"
                      id="startseiteButtonStart"
                      onClick={(e) => handleClick(e)}
                    >
                      {i18n.t("Start")}
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <Carousel
                    activeIndex={startseiteIndex}
                    onSelect={handleSelectStartseite}
                    interval={6000}
                    fade={true}
                    keyboard={false}
                    indicators={true}
                    touch={true}
                    controls={true}
                    className="startseiteCarousel"
                  >
                    {[...Array(5)].map((item, idx) => (
                      <Carousel.Item key={idx}>
                        <div className="startseiteCarouselPersona">
                          <img
                            src={ermittleAvatar(idx)}
                            alt=""
                            width="500em"
                            className="startseiteCarouselImg"
                          />
                        </div>
                        <div className="startseiteCarouselDesc">„{i18n.t(ermittleClaim(idx))}“</div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </Carousel.Item>

            {/* Selbsteinschaetzung */}
            <Carousel.Item>
              {/* <div className="sticky-top bg-light"> */}
                <div className="col-12 text-center navText pb-3">
                  <div className="pb-1">
                    <span className="navTextNumberBig">{String(0).padStart(2, "0")}</span>
                    <span className="navTextNumber"> / {template.questions.length}</span>
                  </div>
                  <div id="frageSE">{i18n.t("Wie schätzt du dich ein?")}</div>
                </div>
              {/* </div> */}
              {[0].map(() => {
                var selbsteinschaetzungIdxCounter = 0
                return (
                  <React.Fragment key={"selbsteinschaetzung"}>
                    {[
                      template.selbsteinschaetzungQuestions.slice(0, 3),
                      template.selbsteinschaetzungQuestions.slice(3),
                    ].map(
                      //Aufteilung in zwei Gruppen (oben, unten)
                      (selbsteinschaetzungChunkItem, selbsteinschaetzungChunkIdx) => {
                        return (
                          <React.Fragment key={selbsteinschaetzungChunkIdx}>
                            <div className="row" id="rowSelbsteinschaetzung">
                              {selbsteinschaetzungChunkItem.map((_, __) => {
                                selbsteinschaetzungIdxCounter++
                                let selbsteinschaetzungIdx = selbsteinschaetzungIdxCounter - 1
                                return (
                                  <React.Fragment key={selbsteinschaetzungIdx}>
                                    <div
                                      className={
                                        (selbsteinschaetzungChunkIdx === 0
                                          ? "col-md-4"
                                          : "col-md-6") + " text-center pb-1"
                                      }
                                      
                                    >
                                      <Comic
                                        type="selbsteinschaetzung"
                                        content={i18n.t(
                                          template.selbsteinschaetzungQuestions[
                                            selbsteinschaetzungIdx
                                          ].question
                                        )}
                                        persona={
                                          template.selbsteinschaetzungQuestions[
                                            selbsteinschaetzungIdx
                                          ].persona
                                        }
                                        position={selbsteinschaetzungIdx}
                                        onClick={(e) => handleClick(e)}
                                        isClickDisabled={isAuswertung ? true : false}
                                        className={
                                          personaSelbsteinschaetzung > -1 &&
                                          template.selbsteinschaetzungQuestions[
                                            selbsteinschaetzungIdx
                                          ].persona !== personaSelbsteinschaetzung
                                            ? "selbsteinschaetzugGrau"
                                            : ""
                                        }
                                      />
                                    </div>
                                  </React.Fragment>
                                )
                              })}
                            </div>
                          </React.Fragment>
                        )
                      }
                    )}
                  </React.Fragment>
                )
              })}
            </Carousel.Item>

            {/* Fragen */}
            {template.questions.map((questionsItem, questionsIdx) => {
              const fragen = template.questions[questionsIdx]

              let historySelectedAnswerIdx = personaHistory[questionsIdx]
              //Berechnung, welche Antwort auf welche Persona einzählt
              const filteredAnswersIdxForAuswertungSelectedPersona = questionsItem.answers.flatMap(
                (currentValue, index, array) =>
                  currentValue.beneficiary.includes(auswertungSelectedPersona) ? index : []
              )

              return (
                <Carousel.Item key={questionsIdx}>
                  {isAuswertung ? (
                    <>
                    {/* <div className="sticky-top bg-light"> */}
                        <div className="col-12 text-center navText pb-3">
                          <div className="pb-1">
                            <span className="navTextNumberBig">
                              {String(questionsIdx + 1).padStart(2, "0")}
                            </span>
                            <span className="navTextNumber"> / {template.questions.length}</span>
                          </div>
                          <div>{i18n.t(questionsItem.question)}</div>
                        </div>
                     {/* </div> */}
                      {/* prettier-ignore */}
                      <div className={ "col-12 text-center pb-3" + (isAuswertung ? " navTextAuswertung" : "")}>
                          - {i18n.t("Klicke auf einen Datenschutztyp, um zu sehen, welche Antwort ihm entspricht.")} -
                        </div>
                      <div className="d-flex justify-content-center align-items-center pb-4">
                        {Array.from({ length: 5 }, (_, personaIdx) => (
                          <React.Fragment key={personaIdx}>
                            <Comic
                              type="auswertung"
                              persona={personaIdx}
                              position={personaIdx}
                              onClick={(e) => handleClick(e)}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  ) : (
                    /*<div className="sticky-top bg-light">*/
                      <div className="col-12 text-center navText pb-3">
                        <div className="pb-1">
                          <span className="navTextNumberBig">
                            {String(questionsIdx + 1).padStart(2, "0")}
                          </span>
                          <span className="navTextNumber"> / {template.questions.length}</span>
                        </div>
                        <div id="frage">{i18n.t(questionsItem.question)}</div>
                      </div>
                    /*</div>*/
                  )}

                  <div className="row">
                    <img
                      src={ermittleFrageBg(questionsIdx)}
                      className="mx-auto d-none d-md-block frageHintergrund"
                      width={400}
                      alt=""
                    />
                    <img
                      src={ermittleFrageBg(questionsIdx)}
                      className="mx-auto d-block d-md-none"
                      width={300}
                      alt=""
                    />
                    {[0].map(() => {
                      var slices = []
                      if (questionsItem.answers.length === 6) {
                        slices = [questionsItem.answers.slice(0, 3), questionsItem.answers.slice(3)]
                      } else if (questionsItem.answers.length === 5){
                        slices = [questionsItem.answers.slice(0, 3), questionsItem.answers.slice(3)]
                      }
                      else {
                        slices = [questionsItem.answers.slice(0, 2), questionsItem.answers.slice(2)]
                      }
                      var answersIdxCounter = 0
                      return (
                        <React.Fragment key={"fragen"}>
                          {slices.map(
                            //Aufteilung in zwei Gruppen (links, rechts)
                            (questionChunkItem, answersChunkIdx) => {
                              return (
                                <React.Fragment key={answersChunkIdx}>
                                  <div className="col-md-6">
                                    {questionChunkItem.map((_, __) => {
                                      answersIdxCounter++
                                      let answersIdx = answersIdxCounter - 1
                                      return (
                                        <React.Fragment key={answersIdx}>
                                          <div
                                            className={
                                              "row flex-nowrap justify-content-between align-items-center p-2" +
                                              (historySelectedAnswerIdx > -1 &&
                                              +answersIdx !== historySelectedAnswerIdx
                                                ? " antwortGrau"
                                                : "")
                                            } 
                                          >
                                            <div className="col-2 text-right" id="antwortNummern">{answersIdx + 1}</div>
                                            <div className="col-12" id="einzelAntwort">
                                              <Comic
                                                type="fragen"
                                                content={i18n.t(fragen.answers[answersIdx].text)}
                                                position={answersIdx}
                                                onClick={(e) => handleClick(e)}
                                                isClickDisabled={isAuswertung ? true : false}
                                                className={
                                                  (answersChunkIdx % 2
                                                    ? " antwortAlignRight"
                                                    : "") +
                                                  (isAuswertung &&
                                                  pageIndex === questionsIdx + 2 &&
                                                  filteredAnswersIdxForAuswertungSelectedPersona.includes(
                                                    answersIdx
                                                  )
                                                    ? " highlighedPersona" +
                                                      String.fromCharCode(
                                                        65 + auswertungSelectedPersona
                                                      )
                                                    : "")
                                                }
                                              />
                                              <hr className="antwortDivider" />
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      )
                                    })}
                                  </div>
                                </React.Fragment>
                              )
                            }
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </Carousel.Item>
              )
            })}

            {/* Ergebnis #1 */}
            {[0].map(() => {
              //Berechnen
              var personaResultsCounter = [0, 0, 0, 0, 0]
              personaHistory.forEach(function (historyItem, idx) {
                if (historyItem !== -1) {
                  var beneficiary = template.questions[idx].answers[historyItem].beneficiary
                  beneficiary.forEach((element) => {
                    personaResultsCounter[element] += 1
                  })
                }
              })
              let personaResultsCounterTotal = personaResultsCounter.reduce(
                (accum, item) => accum + item,
                0
              )
              let personaRanking = Array.from(Array(personaResultsCounter.length).keys()).sort(
                (a, b) => parseInt(personaResultsCounter[b]) - parseInt(personaResultsCounter[a])
              )
              let personaRankingBest = personaRanking[0]

              return (
                <Carousel.Item key={"ergebnis1"}>
                  
                    <div className="col-12 text-center navText pb-4">
                      <div className="pb-1">
                        <span className="navTextNumberBig">{i18n.t("Ergebnis")} 01</span>
                        <span className="navTextNumber"> / 03</span>
                      </div>
                      <div id="auswertung1Head">
                        {i18n.t("Du gehörst zu")}{" "}
                        {Math.round(
                          (personaResultsCounter[personaRankingBest] / personaResultsCounterTotal) *
                            100
                        )}
                        {i18n.t("% der Gruppe der")}{" "}
                        {i18n.t(ermittleName(personaRankingBest, true))}
                        {i18n.t(" an.")}
                      </div>
                    </div>
                  

                  <div className="ergebnis1Result">
                    <div className="ergebnis1Top">
                      <Comic
                        type="ergebnis1"
                        persona={personaRankingBest}
                        className="ergebnis1Img"
                      />
                      <div
                        className={
                          "ergebnis1ResultPercentage textcolorPersona" +
                          String.fromCharCode(65 + personaRankingBest)
                        }
                      >
                        {Math.round(
                          (personaResultsCounter[personaRankingBest] / personaResultsCounterTotal) *
                            100
                        ) + "%"}
                      </div>
                    </div>
                    <div>
                      <div className="ergebnis1ResultClaim">
                        „{i18n.t(ermittleClaim(personaRankingBest))}“
                      </div>
                      <hr />
                      <div className="ergebnis1ResultDesc">
                        {i18n.t(template.bewertungTexte[personaRankingBest].text)}
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              )
            })}

            {/* Ergebnis #2 */}
            {[0].map(() => {
              //Berechnen
              var personaResultsCounter = [0, 0, 0, 0, 0]
              personaHistory.forEach(function (historyItem, idx) {
                if (historyItem !== -1) {
                  var beneficiary = template.questions[idx].answers[historyItem].beneficiary
                  beneficiary.forEach((element) => {
                    personaResultsCounter[element] += 1
                  })
                }
              })
              let personaResultsCounterTotal = personaResultsCounter.reduce(
                (accum, item) => accum + item,
                0
              )
              let personaRanking = Array.from(Array(personaResultsCounter.length).keys()).sort(
                (a, b) => parseInt(personaResultsCounter[b]) - parseInt(personaResultsCounter[a])
              )

              return (
                <Carousel.Item key={"ergebnis2"}>
                    <div className="row">
                      <div className="col-12 text-center navText pb-3">
                        <div className="pb-1">
                          <span className="navTextNumberBig">{i18n.t("Ergebnis")} 02</span>
                          <span className="navTextNumber"> / 03</span>
                        </div>
                        <div id="auswertung2Head">
                          {i18n.t(
                            "Hier siehst du, zu wie viel Prozent deine Antworten den verschiedenen Datenschutztypen entsprechen."
                          )}
                        </div>
                      </div>
                    </div>
                  

                  <p className="text-center">
                    {i18n.t("Deine ursprüngliche Auswahl")}:{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip>{i18n.t(ermittleName(personaSelbsteinschaetzung))}</Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <img src={ermittleAvatar(personaSelbsteinschaetzung)} width={60} alt="" />
                      </span>
                    </OverlayTrigger>
                  </p>

                  {[0].map(() => {
                    var personaRankingIdxCounter = 0
                    return (
                      <React.Fragment key={"auswertung"}>
                        {[personaRanking.slice(0, 3), personaRanking.slice(3)].map(
                          //Aufteilung in zwei Gruppen (oben, unten)
                          (personaRankingChunkItem, personaRankingChunkIdx) => {
                            return (
                              <React.Fragment key={personaRankingChunkIdx}>
                                <div className="row" id="rowErgebnis2">
                                  {personaRankingChunkItem.map((_, __) => {
                                    personaRankingIdxCounter++
                                    let personaRankingIdx = personaRankingIdxCounter - 1
                                    let personaResultsIdx = personaRanking[personaRankingIdx]
                                    return (
                                      <React.Fragment key={personaResultsIdx}>
                                        <div
                                          className={
                                            (personaRankingChunkIdx === 0
                                              ? "col-md-4"
                                              : "col-md-6") + " text-center pb-1"
                                          }
                                        >
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip>
                                                {i18n.t(ermittleName(personaResultsIdx))}
                                              </Tooltip>
                                            }
                                          >
                                            <span className="d-inline-block">
                                              <div className="ergebnis2Top mt-3">
                                                <Comic
                                                  type="ergebnis2"
                                                  persona={personaResultsIdx}
                                                  className="ergebnis2Img"
                                                />
                                                <div
                                                  className={
                                                    "ergebnis2ResultPercentage textcolorPersona" +
                                                    String.fromCharCode(65 + personaResultsIdx)
                                                  }
                                                >
                                                  {Math.round(
                                                    (personaResultsCounter[personaResultsIdx] /
                                                      personaResultsCounterTotal) *
                                                      100
                                                  )}
                                                  %
                                                </div>
                                              </div>
                                            </span>
                                          </OverlayTrigger>
                                          <div className="ergebnis2ResultDesc">
                                          <div className="result2PersonaName"><i>{i18n.t(ermittleName(personaResultsIdx))}</i></div><br></br>
                                            {i18n.t(
                                              template.bewertungTexte[personaResultsIdx].text
                                            )}
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    )
                                  })}
                                </div>
                              </React.Fragment>
                            )
                          }
                        )}
                      </React.Fragment>
                    )
                  })}
                </Carousel.Item>
              )
            })}

            {/* Ergebnis #3 */}
            {[0].map(() => {

                 //Berechnen
                 var personaResultsCounter = [0, 0, 0, 0, 0]
                 personaHistory.forEach(function (historyItem, idx) {
                   if (historyItem !== -1) {
                     var beneficiary = template.questions[idx].answers[historyItem].beneficiary
                     beneficiary.forEach((element) => {
                       personaResultsCounter[element] += 1
                     })
                   }
                 })
                 let personaResultsCounterTotal = personaResultsCounter.reduce(
                   (accum, item) => accum + item,
                   0
                 )
                 let personaRanking = Array.from(Array(personaResultsCounter.length).keys()).sort(
                   (a, b) => parseInt(personaResultsCounter[b]) - parseInt(personaResultsCounter[a])
                 )
                 let personaRankingBest = personaRanking[0]

              return (
                <Carousel.Item key={"ergebnis3"}>
                  <div className="bg-light">
                      <div className="col-12 text-center navText pb-3">
                        <div className="pb-1">
                          <span className="navTextNumberBig">{i18n.t("Ergebnis")} 03</span>
                          <span className="navTextNumber"> / 03</span><br></br><br></br>
                          <h5>{i18n.t("Fast geschafft! Hier findest du unsere abschließenden Empfehlungen für dich.")}</h5>
                          <h5>{i18n.t("Am Ende der Seite erfährst du, wie es weiter geht.")}</h5>
                          <hr></hr>
                        
                        </div>
                    </div>
                  </div>

                  <div className="row" id="rowErgebnis3">
                         <div className="ergebnis3ResultDesc">
                        {i18n.t(template.tippTexte[personaRankingBest].text)}<br></br><br></br><p><img src={ergebnis3img} alt="Person mit Lupe" id="ergebnis3img"/></p><br></br>
                        {i18n.t(template.tippLinks[personaRankingBest].text1)}{(template.tippLinks[personaRankingBest].text2)}<br></br><br></br><hr></hr><br></br>
                        {i18n.t(template.tippLinks[personaRankingBest].text3)}{(template.tippLinks[personaRankingBest].text4)}
                      </div>
                      </div>

                  <div className="row" id="weiteresText">
                  
                  
                    <div className="col-12 text-center"><span><h4><b>{i18n.t("ENDE DER AUSWERTUNG")}</b></h4></span><hr></hr>
                    <h5>{i18n.t("Toll gemacht! Du hast den Privat-o-Mat erfolgreich abgeschlossen.")}</h5>
                    <br></br>{i18n.t(template.infobox.weiteres)}
                    {i18n.t(template.infobox.weiteres2)}</div>
                  </div>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>


        {/* Footer */}
        <Footer
          showPrevButton={pageIndex === 0 ? false : true}
          showProgress={
            pageIndex === 0 || pageIndex >= template.questions.length + 2 || isAuswertung === true
              ? false
              : true
          }
          progressValue={(pageIndex / (template.questions.length + 2)) * 100 + "%"}
          showNextButton={pageIndex === 0 && isAuswertung === false ? false : true}
          onClick={(e) => handleClick(e)}
          disableNextButton={
            (pageIndex === 1 && personaSelbsteinschaetzung > -1) ||
            personaHistory[pageIndex - 2] > -1 ||
            pageIndex >= template.questions.length + 2
              ? false
              : true
          }
        />
      </div>
    </>
  )
}

export default App
