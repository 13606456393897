import bildPro from "../assets/img/personaPro.png"
import bildTeilzeit from "../assets/img/personaTeilzeit.png"
import bildUnwissender from "../assets/img/personaUnwissender.png"
import bildEgalo from "../assets/img/personaEgalo.png"
import bildEnthusiast from "../assets/img/personaEnthusiast.png"

import bgPro from "../assets/img/personaProBg.svg"
import bgTeilzeit from "../assets/img/personaTeilzeitBg.svg"
import bgUnwissender from "../assets/img/personaUnwissenderBg.svg"
import bgEgalo from "../assets/img/personaEgaloBg.svg"
import bgEnthusiast from "../assets/img/personaEnthusiastBg.svg"

import bgFrage1 from "../assets/img/frage1.png"
import bgFrage2 from "../assets/img/frage2.png"
import bgFrage3 from "../assets/img/frage3.png"
import bgFrage4 from "../assets/img/frage4.png"
import bgFrage5 from "../assets/img/frage5.png"
import bgFrage6 from "../assets/img/frage6.png"
import bgFrage7 from "../assets/img/frage7.png"
import bgFrage8 from "../assets/img/frage8.png"
import bgFrage9 from "../assets/img/frage9.png"
import bgFrage10 from "../assets/img/frage10.png"
import bgFrage11 from "../assets/img/frage11.png"
import bgFrage12 from "../assets/img/frage12.png"
import bgFrage13 from "../assets/img/frage13.png"
import bgFrage14 from "../assets/img/frage14.png"
import bgFrage15 from "../assets/img/frage15.png"

class persona {
  static #_Pro = 0
  static #_Teilzeit = 1
  static #_Unwissender = 2
  static #_Egalo = 3
  static #_Enthusiast = 4

  static get Pro() {
    return this.#_Pro
  }
  static get Teilzeit() {
    return this.#_Teilzeit
  }
  static get Unwissender() {
    return this.#_Unwissender
  }
  static get Egalo() {
    return this.#_Egalo
  }
  static get Enthusiast() {
    return this.#_Enthusiast
  }
}

export function ermittleName(nummer, beugung = false) {
  if (nummer === persona.Pro) {
    if (beugung) {
      return "reflektierten Datenschützerinnen und -Datenschützer"
    }
    return "reflektierte Datenschützerinnen und -Datenschützer"
  } else if (nummer === persona.Teilzeit) {
    return "Teilzeit-Datenschützerinnen und -Datenschützer"
  } else if (nummer === persona.Unwissender) {
    if (beugung) {
      return "unbedarften Surferinnen und Surfer"
    }
    return "unbedarfte Surferinnen und Surfer"
  } else if (nummer === persona.Egalo) {
    return "Egalos"
  } else if (nummer === persona.Enthusiast) {
    return "Digital-Enthusiastinnen und Enthusiasten"
  }
}

export function ermittleClaim(nummer) {
  if (nummer === persona.Pro) {
    return "Datenschutz ist mir sehr wichtig! Dafür bin ich gerne bereit, auf bestimmte Dinge zu verzichten."
  } else if (nummer === persona.Teilzeit) {
    return "Ich versuche, meine Daten zu schützen, aber auf manche Dienste möchte ich einfach nicht verzichten."
  } else if (nummer === persona.Unwissender) {
    return "Ich habe mich mit Datenschutz bislang noch nicht beschäftigt."
  } else if (nummer === persona.Egalo) {
    return "Um Datenschutz mache ich mir keine Sorgen, ich habe ja nichts zu verbergen."
  } else if (nummer === persona.Enthusiast) {
    return "Ich probiere alle neuen digitalen Produkte und Dienste aus! Datenschutz kommt erst an zweiter Stelle."
  }
}

export function ermittleAvatar(nummer) {
  var output
  if (nummer === persona.Pro) {
    output = bildPro
  } else if (nummer === persona.Teilzeit) {
    output = bildTeilzeit
  } else if (nummer === persona.Unwissender) {
    output = bildUnwissender
  } else if (nummer === persona.Egalo) {
    output = bildEgalo
  } else if (nummer === persona.Enthusiast) {
    output = bildEnthusiast
  }
  return output
}

export function ermittleAvatarBg(nummer) {
  var output
  if (nummer === persona.Pro) {
    output = bgPro
  } else if (nummer === persona.Teilzeit) {
    output = bgTeilzeit
  } else if (nummer === persona.Unwissender) {
    output = bgUnwissender
  } else if (nummer === persona.Egalo) {
    output = bgEgalo
  } else if (nummer === persona.Enthusiast) {
    output = bgEnthusiast
  }
  return output
}

export function ermittleFrageBg(nummer) {
  var output

  if (nummer === 0) {
    output = bgFrage1
  } else if (nummer === 1) {
    output = bgFrage2
  } else if (nummer === 2) {
    output = bgFrage3
  } else if (nummer === 3) {
    output = bgFrage4
  }
  else if (nummer === 4) {
    output = bgFrage5
  }
  else if (nummer === 5) {
    output = bgFrage6
  }
  else if (nummer === 6) {
    output = bgFrage7
  }
  else if (nummer === 7) {
    output = bgFrage8
  }
  else if (nummer === 8) {
    output = bgFrage9
  }
  else if (nummer === 9) {
    output = bgFrage10
  }
  else if (nummer === 10) {
    output = bgFrage11
  }
  else if (nummer === 11) {
    output = bgFrage12
  }
  else if (nummer === 12) {
    output = bgFrage13
  }
  else if (nummer === 13) {
    output = bgFrage14
  }
  else if (nummer === 14) {
    output = bgFrage15
  }
  console.log(output)
  return output
}

export default persona
