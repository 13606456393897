import React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import i18n from "../logic/translation"

function Footer(props) {
  return (
    <>
      {(props.showPrevButton || props.showProgress || props.showNextButton) && (
        <footer className="fixed-bottom">
          <div className="row flex-nowrap justify-content-between align-items-center">
            <div className="col-4">
              {props.showPrevButton ? (
                <button
                  type="button"
                  className="btn btn-light btn-md border border-dark text-uppercase"
                  id="footerPrev"
                  onClick={(e) => props.onClick(e)}
                >
                  {i18n.t("Zurück")}
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="col-4 text-center">
              {props.showProgress && (
                <div className="pt-2 pb-2">
                  <div className="progress" style={{ height: "2px" }}>
                    <div
                      className="progress-bar bg-dark"
                      role="progressbar"
                      style={{ width: props.progressValue }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-4 pt-1 text-right">
              {props.showNextButton ? (
                <>
                  {props.disableNextButton ? (
                    <div>
                      <div>
                        <OverlayTrigger
                          trigger="click"
                          overlay={
                            <Tooltip>{i18n.t("Du hast noch keine Auswahl getroffen!")}</Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <a
                              href="./#"
                              className="btn btn-light btn-md border disabled text-uppercase"
                              role="button"
                              aria-disabled="true"
                              id="footerNext"
                            >
                              {i18n.t("Weiter")}
                            </a>
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-light btn-md border border-dark text-uppercase"
                      id="footerNext"
                      onClick={(e) => props.onClick(e)}
                      disabled={props.disableNextButton}
                    >
                      {i18n.t("Weiter")}
                    </button>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </footer>
      )}
    </>
  )
}

export default Footer
