import i18n from "i18next"

//Regex
//"(.*?)": "(.*?)" => "$1": "$1"

/* prettier-ignore */
const resources = {
    en: {
      translation: {
        /* Startseite */
        "Hallo und herzlich willkommen beim Privat-o-Mat.": "Hello and welcome to the 'Privat-o-Mat' (Privacymeter).",
        "Entscheidungen zum Thema Datenschutz und zum Schutz der eigenen Privatsphäre sind Teil des digitalen Alltags geworden. Deshalb helfen wir dir dabei, deine Einstellung und dein persönliches Verhalten dazu zu reflektieren und besser zu verstehen.":"Decisions on data protection and privacy matters have become part of everyday digital life. We would like to help you understand them better by reflecting on your personal habits and customs in the digital space.",
        "Viel Spaß dabei!": "We hope you have fun with it!",
        "Info": "Info",
        "Start": "Start",

        "Für Informationen zur barrierefreien Nutzung, klicke auf 'Info' oder drücke die Taste 'I' auf deiner Tastatur.": "For accessibility information, please click on 'Info' or press 'I' on your keyboard.",
  
        /* Persona */
        "reflektierten Datenschützerinnen und -Datenschützer": "Reflective Data Protectionists",
        "reflektierte Datenschützerinnen und -Datenschützer": "Reflective Data Protectionists",
        "Teilzeit-Datenschützerinnen und -Datenschützer": "Part-Time Data Protectionists",
        "unbedarften Surferinnen und Surfer": "Inexperienced Surfers",
        "unbedarfte Surferinnen und Surfer": "Inexperienced Surfers",
        "Egalos": "Digital Apathetics",
        "Digital-Enthusiastinnen und Enthusiasten": "Digital Enthusiasts",

        "Datenschutz ist mir sehr wichtig! Dafür bin ich gerne bereit, auf bestimmte Dinge zu verzichten.": "Data protection is very important to me! For that, I’m happy to do without certain tools and applications if necessary.",
        "Ich versuche, meine Daten zu schützen, aber auf manche Dienste möchte ich einfach nicht verzichten.": "I try to protect my data, but I can’t do without the usage of some services.",
        "Ich habe mich mit Datenschutz bislang noch nicht beschäftigt.": "I haven’t dealt with data protection yet.",
        "Um Datenschutz mache ich mir keine Sorgen, ich habe ja nichts zu verbergen.": "I'm not worried about data privacy because I have nothing to hide.",
        "Ich probiere alle neuen digitalen Produkte und Dienste aus! Datenschutz kommt erst an zweiter Stelle.": "I like trying every new digital product and service offered! Data protection comes second.",
  
        /* Navigation */
        "Home": "Home",
        "Impressum": "Imprint",
        "Datenschutz": "Privacy statement",
        "Lizenzhinweise Dritter": "License Notice",
        "Möchtest du deine bisherigen Eingaben löschen und mit dem Privat-o-Mat von vorne beginnen?": "Would you like to delete your previous input and restart the Privat-o-Mat?",

        /* Footer */
        "Zurück": "Back",
        "Weiter": "Next",
        "Du hast noch keine Auswahl getroffen!": "You haven't made a selection yet!",

        /* Infobox */
        "Schliessen": "Close",

        "Wie funktioniert der Privat-o-Mat?":"How does the 'Privat-o-Mat' work?",
        "Mit dem Privat-o-Mat möchten wir dir helfen, deine Einstellung und dein persönliches Verhalten zum Thema Datenschutz zu reflektieren und besser zu verstehen, damit du im digitalen Alltag ganz bewusst eigene Entscheidungen treffen kannst. Dazu stellen wir dir 15 Fragen aus verschiedenen Bereichen des digitalen Alltags und stellen dir jeweils verschiedene Antwortmöglichkeiten zur Verfügung. Aus diesen Antwortmöglichkeiten wählst du jeweils diejenige aus, die am ehesten deinem persönlichen Verhalten entsprechen würde. Wenn du alle Fragen beantwortet hast, zeigt dir der Privat-o-Mat deinen persönlichen Datenschutztyp und gibt dir ein paar Tipps, wie du noch besser durch den digitalen Alltag kommst.":"With the 'Privat-o-Mat' we would like to help you to reflect and understand better your customs and habits on data protection so that you can make your own conscious decisions in everyday digital life. To do this, we ask you 15 questions on different aspects of daily digital life and offer you different options to answer. From these options, you select the one that most closely corresponds to your personal behavior. As soon as you have answered all the questions, the 'Privat-o-Mat' will show you your personal data protection type and give you some additional tips on how to manage your everyday digital life even better.",

        "Klicke auf „Start“, um den Privat-o-Mat zu starten. Wir wünschen dir viel Spaß!": "Click on 'Start' to begin. We hope you have fun!",

        "Barrierefreiheit": "Accessibility",
        "Der Privat-o-Mat lässt sich ohne Maus unter Nutzung der Tastatur bedienen. Die Tastenbelegung lautet dabei wie folgt. ": "The 'Privat-o-Mat' can be operated without a mouse using the keyboard. The key assignment reads as follows. ",
        "'H' = Menüpunkt 'Home', ": "'H' = Menu item 'Home', ",
        "'I' = Menüpunkt 'Info', ": "'I' = Menu item 'Info',", 
        "'L' = Wechsel der Sprache, ": "'L' = Change of language, ",
        "'P' = Menüpunkt 'Impressum, Lizenzen & Datenschutz', ": "'P' = Menu item 'Imprint, Licences & Privacy', ",
        "'Esc' = Fenster schließen, ": "'Esc' = Close window, ",
        "'Enter' = Eingabe / Start / Weiter, ": "'Enter' = Enter / Start / Next, ",
        "'Rechte Pfeiltaste' = Start / Weiter, ": "'Right arrow key' = Start / Next, ",
        "'Linke Pfeiltaste' = Zurück, ": "'Left arrow key' = Back, ",
        "'Tasten 1-6' = Auswahl der Antwortmöglichkeiten (von links oben nach links unten, weiterführend von rechts oben nach rechts unten) ": "'Keys 1-6' = Selection of answer options (from top left to bottom left, continuing from top right to bottom right) ",
  


        "Was passiert mit deinen Daten?": "What will happen to my data?",
        "Deine Angaben werden ausschließlich für die Auswertung deines Datenschutztyps genutzt, die dir am Ende des Prozesses angezeigt wird. Der Privat-o-Mat speichert keine deiner Angaben und sammelt darüber hinaus keine weiteren Nutzerdaten.": "Your data will only be used to create your data protection type, which will be displayed at the end of the questionnaire. The 'Privat-o-Mat' won’t save any of your choices nor any additional user data.",

        "Wer steckt hinter dem Privat-o-Mat?":"Who’s the team behind the 'Privat-o-Mat'?",
        "Der Privat-o-Mat ist ein Kooperationsprojekt zwischen dem Institut für Digitale Ethik (IDE) an der Hochschule der Medien Stuttgart (zuständige Mitarbeiterinnen und Mitarbeiter siehe Impressum) und dem Landesbeauftragten für Datenschutz und Informationsfreiheit Baden-Württemberg (zuständige Mitarbeiterin Clarissa Henning). Darüber hinaus unterstützt der Südwestrundfunk (SWR) das Projekt als Medienpartner.": "The 'Privat-o-Mat' is a cooperation project between the Institute for Digital Ethics at the Stuttgart Media University (responsible staff members see below) and the State Commissioner for Data Protection and Freedom of Information of Baden-Württemberg (responsible staff members Nico Gasch & Clarissa Henning).  Furthermore, the Südwestrundfunk (SWR) supports the project as media partner.",

        "Der Privat-o-Mat ist ein Produkt des Instituts für Digitale Ethik der Hochschule der Medien Stuttgart. Alle Inhalte wurden von einem Redaktionsteam aus Studierenden der Hochschule der Medien Stuttgart und Mitarbeiterinnen und Mitarbeitern sowie den Verantwortlichen des Instituts für Digitale Ethik entwickelt": "The 'Privat-o-Mat' is a product of the Institute for Digital Ethics at Stuttgart Media University. All content was developed by an editorial team consisting of students of the Stuttgart Media University and its staff as well as the steering committee of the Institute for Digital Ethics.",

        "Diensteanbieter gemäß § 5 Telemediengesetz (TMG)": "Service provider according to § 5 Telemediengesetz (TMG)",
        "Die Hochschule der Medien Stuttgart ist eine Körperschaft des öffentlichen Rechts. Sie wird durch den Rektor Professor Dr. Alexander W. Roos gesetzlich vertreten.": "The Hochschule der Medien Stuttgart is a corporation under public law. It is legally represented by the Rector Professor Dr. Alexander W. Roos.",
        "Verantwortlich gemäß § 18 Medienstaatsvertrag (MStV)": "Responsible according to § 18 Media State Treaty (MStV)",
        "Leitungsgremium des Instituts für Digitale Ethik (Anschrift s.o.)": "Steering Committee of the Institute for Digital Ethics (see above for address)",

        "Studierende der Hochschule der Medien": "Students of the Hochschule der Medien",
        "Mitarbeiterinnen und Mitarbeiter und Verantwortliche des Instituts für Digitale Ethik": "Staff and Steering Committee of the Institute for Digital Ethics",

        "Mit freundlicher Unterstützung des Landesbeauftragten für Datenschutz und Informationsfreiheit Baden-Württemberg.": "With the kind support of the State Commissioner for Data Protection and Freedom of Information Baden-Württemberg.",

        "Inhalt und Redaktion": "Content and editing",
        "Umsetzung, Gestaltungskonzept, Design & Illustration": "Implementation, design concept, design & illustration",
        "Programmierung": "Programming",
        "Nach einer Idee von": "Based on an idea by",

        /* Impressum & Lizenzen */

        "Impressum, Lizenzen": "Imprint, Licenses",
        "& Datenschutz": "& Data protection",

        "Impressum, Lizenzhinweise Dritter & Datenschutz": "Imprint, Third Party License Notes & Data Protection Regulations",
        "Diese Webseite enthält ggf. Material von Drittanbietern, welches unter den im folgenden aufgeführten Bestimmungen lizenziert ist.": "This website may contain third party material which is licensed under the terms set out below.",
        
        "Datenschutz": "Data Protection Regulations",
        "Für diese Webseite gelten die Datenschutzbestimmungen der Hochschule der Medien Stuttgart.": "The data protection regulations of the Stuttgart Media University apply to this website.",

        /* Selbsteinschätzung */
        "Wie schätzt du dich ein?": "What applies most to you?",

        /* Fragen */

         /* 01 */
        "Welche Messengerdienste benutzt du?": "Which messenger services do you use?",
        
        "Ich nutze den Messenger, der in meinem persönlichen Umfeld am häufigsten genutzt wird.": "I use the messenger that’s mostly used among my circle of friends and family.",
        "Ich nutze verschiedene Messengerdienste und probiere gerne neue Dienste aus, um immer auf dem neusten technischen Stand zu sein.": "I use different messengers and I also like to check out new ones to stay up to date with the latest technology.",
        "Ich nutze überhaupt keine Messengerdienste, sondern greife lieber auf andere Kommunikationsmittel zurück wie beispielsweise SMS, Telefon, E-Mail etc.": "I don’t use any messenger services. I prefer using different means of communication like SMS, phone calls and e-mail.",
        "Ich versuche, möglichst datenschutzfreundliche Dienste wie Signal zu benutzen, aber ich kommuniziere auch über andere Dienste, weil ich bestimmte Kontakte nicht verlieren möchte.": "I try to use privacy-friendly services like Signal as much as possible, but I also communicate via other services because I want to stay in contact with certain people.",
        "Ich nutze ausschließlich datenschutzfreundliche Dienste, über die ich mich vorher genau informiert habe.": "I only use privacy-friendly services about which I have informed myself in detail beforehand.",
        "Ich nutze den Messenger, der mir die meisten Vorteile bietet. Der Datenschutz ist dabei aber kein entscheidendes Kriterium für mich.": "I use the messenger that offers me the most advantages. Data protection is not a decisive criterion for me.",

        /* 02 */

        "Worauf achtest du beim Kauf eines neuen Smartphones?": "Which criteria are important for you when buying a new smartphone?",
        
        "Ich entscheide mich immer für die neuesten Spitzenmodelle mit den besten Features.": "I always go for the latest generation with the best features.",
        "Ich achte auf das beste Preis-Leistungs-Verhältnis und entscheide hauptsächlich danach, wie mir das Gerät gefällt und wie es sich bedienen lässt.": "I consider the price/performance ratio and decide mainly on the device that  is most visually appealing  and that has the best usability.",
        "Ich entscheide mich nur für Modelle und Hersteller, bei denen ich weiß, dass der Datenschutz geachtet wird und dass ich selbst detaillierte Privatsphäreeinstellungen auf dem Smartphone treffen kann.": "I only choose models and manufacturers where I know that data protection is respected and  offer detailed privacy settings that I can adjust myself.",
        "Ich achte zwar schon auf die Möglichkeit, datenschutzfreundliche Voreinstellungen zu treffen, aber sie sind nicht das vorrangige Entscheidungskriterium.": "Although I consider the importance of adjustable privacy settings, they are not the most important criterion.",
        "Ich nutze kein Smartphone.": "I don’t use a smartphone.",

        /* 03 */

        "Wie verbindest du Dich mit dem Internet, wenn du unterwegs bist?": "How do you connect to the Internet when you are on the move?",

        "Ich nutze ausschließlich meine mobilen Daten und lehne es aus Datenschutzgründen kategorisch ab, ein öffentliches WLAN zu nutzen.": "I only use my mobile data connection and I strictly oppose using public Wi-Fi for data protection reasons.",
        "Sobald ein öffentliches WLAN zur Verfügung steht, greife ich gerne darauf zurück, ist ja auch besser fürs Datenvolumen.": "When there’s public Wi-Fi available, I like to use it because it’s better for my data volume.",
        "Ich wähle den Weg, der mir den schnellsten Zugang und die beste Datenübertragung ermöglicht.": "I choose the option that offers me the fastest connection and the best data transfer.",
        "Ich nutze meistens meine mobilen Daten, aber bei einem vertrauenswürdigen Anbieter nutze ich auch ein öffentliches WLAN.": "Normally I use my mobile data connection, but when it’s a trustworthy provider, I also use public Wi-Fi networks.",
        "Wenn ich unterwegs bin, bin ich offline.": "I’m offline when I’m on the move.",
    
        /* 04 */

        "Welche Suchmaschine nutzt du?": "Which search engine do you use?",

        "Ich suche auf Google und kenne eigentlich auch gar keine anderen Suchmaschinen.": "I always use Google, and actually don’t know any other search engines.",
        "Aus Bequemlichkeit benutze ich Google oder die vorinstallierte Suchmaschine. Von Datenschutzfragen möchte ich mich dabei nicht ausbremsen lassen.": "I just use Google or the search engine that’s pre-installed on my device. That’s the easiest way. I don’t want to get distracted by questions on data protection.",
        "Ich suche nur über datensparsame Alternativen zu Google wie beispielsweise DuckDuckGo oder Startpage.": "I only search the Internet with data-saving alternatives to Google, such as DuckDuckGo or Startpage.",
        "Ich versuche, alternative Anbieter wie Ecosia und DuckDuckGo häufig zu benutzen, aber oft liefert Google mehr Ergebnisse, und deshalb greife ich hin und wieder auch auf Google zurück.": "I try to use alternative services like Ecosia and DuckDuckGo more often, but in many cases, Google delivers more and better results, and therefore I use it too.",
        "Ich probiere immer verschiedene und neue Anbieter aus und vergleiche gerne die Ergebnisse, weil ich immer über den neusten Stand der Digitalisierung informiert sein möchte, unabhängig vom Datenschutz.": "I always look for new services and compare their results because I want to be informed about the latest developments in digitalization, regardless of data protection.",

        /* 05 */

        "Wie verhältst du dich, wenn du beim Aufrufen einer neuen Webseite nach den Cookie-Einstellungen gefragt wirst?": "How do you behave when you are asked about cookie settings when visiting a new website?",

        "Das nervt mich total, deshalb akzeptiere ich sofort alle Cookies, damit das Pop-Up-Fenster endlich verschwindet.": "These options get on my nerves, so I accept all cookies immediately to make the cookie-pop-up vanish as fast as possible.",
        "Ich nutze generell einen Browser, der keine Cookies zulässt, oder einen Trackingblocker.": "I generally use a tracking blocker or a browser that doesn’t accept cookies.",
        "Ich finde den Einsatz von Cookies sinnvoll und sehe darin sogar Vorteile für mich, deshalb stimme ich gerne zu.": "I believe the use of cookies makes sense and that they offer advantages for me, so I like to agree.",
        "Ich akzeptiere nur das notwendige Setting.": "I accept only necessary settings.",
        "Ich klicke einfach auf Akzeptieren. Ich bin mir gar nicht so sicher, was Cookies eigentlich sind.": "I just click on 'accept'. I’m not really sure what cookies actually are.",

        /* 06 */

        "Meldest du dich nach der Nutzung von Sozialen Netzwerken ab?": "Do you log-out after using social network services?",

        "Ich logge mich nach jeder Nutzung wieder aus oder nutze gar keine Sozialen Netzwerke aus Datenschutzgründen.": "Yes, I log-out after every session, or even don’t use any social networks at all for privacy reasons.",
        "Meistens, wenn ich es nicht vergesse.": "Normally I do, if I don’t forget to.",
        "Nein, denn so erspare ich mir bewusst die erneute Passworteingabe.": "No, why should I? This way I can avoid entering my password again.",
        "Ich bin eigentlich fast immer online.": "I am almost always online.",
        "Darauf habe ich bislang noch nie geachtet.": "I have never reflected on this before.",
        "Ich nutze aus anderen Gründen keine sozialen Netzwerke.": "I don’t use any social networks for other reasons.",

        /* 07 */

        "Wie gehst du bei der Registrierung auf einer neuen Plattform vor?": "How do you go about registering on a new platform?",

        "Ich wähle den für mich bequemsten Weg und nutze, falls möglich, die Anmeldung über bereits bestehende Konten wie Google oder Facebook.": "I choose the most convenient method, usually I register via my existing accounts like Google or Facebook.",
        "Ich verwende immer einen neuen Username und ein sicheres, geschütztes Passwort, das ich professionell über eine spezielle Software oder App erstellen lasse.": "I always choose a new username and a secure and protected password that I create professionally via specialized software or apps.",
        "Meistens denke ich mir einen neuen Zugang und ein Passwort aus, aber manchmal verwende ich die gleichen Passwörter, um sie nicht zu vergessen.": "In most cases, I create a new username and password, but sometimes I reuse the same ones again so I don’t forget them.",
        "Ich nehme eigentlich immer das gleiche Passwort.": "I always use the same password.",
        "Ich habe eine App, die alle meine Passwörter für mich sammelt und speichert, deshalb denke ich mir meistens ein neues Passwort aus.": "I have an app that collects and stores all my passwords, so I normally create a new one everytime.",

        /* 08 */

        "Wie organisierst du Deinen Terminkalender?": "How do you organize your calendar?",

        "Eine digitale Lösung würde ich für private Termine nur verwenden, wenn meine Daten lokal auf dem Gerät gespeichert werden und ich diese auch sicher wieder löschen könnte. In der Arbeit achte ich sehr auf Datenschutz und -sicherheit.": "I would only use a digital solution for my private appointments if my data were stored locally on the device and if I were able to delete them safely. At work, I pay a lot of attention to data protection and security.",
        "Ich benutze eine datenschutzfreundliche Lösung und vertraue dabei auf bekannte Anbieter.": "I always use a privacy-friendly solution, and I trust well-known services.",
        "Meine Termine verraten doch nichts über mich oder mein Privatleben. Deshalb finde ich Datenschutz hier übertrieben.": "My appointments don’t tell anything about my private life. I think data protection is overrated here.",
        "Ich verwende grundsätzlich alle Möglichkeiten und Schnittstellen, die mir die Digitalisierung bietet, und finde es super, wie das meinen Alltag erleichtert.": "I like to use all the services and possibilities that digitalization offers to me on this matter, and I appreciate how that simplifies my daily life.",
        "Ich nutze einfach den Kalender auf meinem Smartphone oder auf meinem Arbeitsrechner.": "I just use the calendar on my smartphone or on my computer at work.",

        /* 09 */

        "Du möchtest eine neue App installieren – Befasst du dich vorher mit den Zugriffsrechten?": "You would like to install a new app - Do you check the access rights beforehand?",

        "Ja. Deshalb kommen bestimmte Apps für mich auch nicht in Frage, wenn diese unverhältnismäßig auf meine Daten zugreifen möchten und ich das nicht unterbinden kann.": "Yes, of course I do. That’s why I don’t use certain apps that want to access my data in a disproportional manner without me being able to avoid that.",
        "Ich versuche, den Datenzugriff im Nachgang über die Einstellungen zu regulieren.": "I try to manage the access to my data afterwards by changing the settings.",
        "Nein, denn ich wüsste auch gar nicht, wo ich diese Informationen finden könnte.": "No, I don’t.  I don’t even know where to find this information.",
        "Ich weiß zwar, dass viele kostenlose Apps mit meinen Daten bezahlt werden, aber das ist mir egal, solange die App gut ist.": "I know that many free apps are actually paid with my data, but I don’t care as long as the app itself is good.",

        /* 10 */

        "Würdest du einen Fitnesstracker benutzen?": "Would you use a fitness tracker?",

        "Ja, weil mir meine Gesundheit wichtig ist und mir ein Fitnesstracker dabei helfen kann, gesund zu leben.": "Yes, I would, because my health matters to me. A fitness tracker can help me to live healthier.",
        "Nein, ich habe keine Fitnesstracker und würde auch keine benutzen wollen.": "No, I don’t have a fitness tracker, and I wouldn’t like to use one.",
        "Ich würde aus Datenschutzgründen keinen Fitnesstracker benutzen, bei dem meine Daten weitergegeben werden. Ich würde das nur dann in Betracht ziehen, wenn ich dabei die volle Kontrolle über meine Daten hätte.": "For reasons of data protection, I would not use a fitness tracker that shares my data. I would only use it if I knew that I were in full control of my data.",
        "Einen Fitnesstracker habe ich nicht, aber ich benutze ab und zu mein Smartphone, um zu wissen, wie viele Schritte ich am Tag gelaufen bin.": "I don’t have a fitness tracker, but I use my smartphone sometimes in order to know how many steps I made in a day.", 
        "Ich würde mir so ein Gerät anschaffen, wenn ich den Hersteller in punkto Datenverarbeitung und -weitergabe für vertrauenswürdig halten würde.": "I would buy such a device if I were convinced that the manufacturer was trustworthy in terms of data processing and sharing.",

        /* 11 */

        "Deaktivierst du bewusst die Ortungsdienste auf deinem Smartphone?": "Do you consciously deactivate the location services on your smartphone?",

        "Ich weiß nicht genau, welche Anwendungen auf meine Standortdaten zugreifen und wie ich das regulieren soll.": "I don’t know exactly which applications access my location data and how to regulate that.",
        "Ich gestatte den Zugriff auf meine Standortdaten, weil ich mir davon bessere Dienstleistungen erhoffe.": "I allow access to my location data because I hope to get better services from it.",
        "Ja, ich deaktiviere oder reguliere den Zugriff auf meine Daten sehr bewusst und ausnahmslos.": "Yes. I disable or regulate access to this data, without exception.",
        "Das kommt auf die Anwendung an. Manche Apps brauchen die Standortdaten und dann genehmige ich den Zugriff. Wahrscheinlich müsste ich es aber regelmäßiger überprüfen.": "It depends on the application. Some apps need the location data and then I’ll allow it. I should probably check my settings more regularly though.",
        "Meine Standortdaten sind für mich keine wirklich persönlichen Informationen, daher ist es mir egal, wer darauf Zugriff hat.": "My location data isn’t really personal information for me, so I don’t care who has access to it.",

        /* 12 */

        "Würdest du einen digitalen Sprachassistenten wie Alexa benutzen?": "Would you use a digital voice assistant like Alexa?",

        "Ja, auf jeden Fall. Ich finde die neue Technik super!": "Yes, of course. I appreciate this new technology!",
        "Ich würde mir nicht extra einen anschaffen, aber ich fände die Technik schon praktisch, wenn sie beispielsweise in meinem neuen Auto verbaut wäre.": "I wouldn’t buy one, but I think this technology would be helpful if it were a feature installed in my new car, for example.",
        "Nein, denn ich möchte die Aufzeichnung und Weitergabe meiner Daten auf jeden Fall vermeiden.": "No, I wouldn’t. I would like to avoid the recording and distributing of my data in any case.",
        "Manche Anwendungen finde ich schon praktisch, aber ich versuche, mir immer bewusst zu machen, welche Daten ich dabei von mir preisgeben könnte.": "I find some applications like these to be helpful, but I always try to be aware of the data I could give away.", 

        /* 13 */

        "Welche Zahlungsmethoden und -anbieter bevorzugst du?": "Which payment methods and services do you prefer?",

        "Bargeld finde ich unpraktisch und etwas altmodisch. Ich nutze sowohl beim Online-Shopping als auch im Geschäft gerne die breite Palette elektronischer Bezahlsysteme, weil sie schnell und unkompliziert funktionieren.": "I consider cash payment to be impractical and somewhat old-fashioned. Whether I go shopping in a local store or online, I always use the wide range of electronic payment systems because they work quickly and easily.",
        "Ich entscheide mich von Fall zu Fall für die jeweils bequemste Variante. Manchmal greife ich auf Bargeld zurück, manchmal nutze ich ein elektronisches Bezahlsystem.": "Well, I decide depending on what’s most convenient. Sometimes I use cash, sometimes I use an electronic payment system.",
        "Auch wenn mich der Gedanke stört, dass meine Kaufinformationen gesammelt werden, greife ich auf ein elektronisches Bezahlsystem zurück, wenn es sich nicht vermeiden lässt.": "Although I don’t like my shopping data to be collected by others, I resort to an electronic payment system when I can’t avoid it.",
        "Ich bevorzuge Bargeldeinkäufe. Lässt es sich gar nicht vermeiden, versuche ich auf elektronische Zahlungsmethoden zurückzugreifen, die von meiner Bank direkt zur Verfügung gestellt werden. In jedem Fall überprüfe ich, ob meine Daten weitergereicht werden könnten, und suche ggf. nach Alternativen.": "I prefer cash payment. If I can’t avoid it, I try to use electronic payment systems offered directly by my bank. In any case, I always check for the possible distribution of my data and search for alternatives if necessary.",

        /* 14 */

        "Wie verhältst du dich beim Online-Shopping?": "How do you behave when shopping online?",

        "Ich nutze den für mich einfachsten und schnellsten Weg. Meistens ist das Amazon.": "I use the easiest and fastest way. Normally that’s by using Amazon.",
        "Ich versuche, das Online-Shopping zu vermeiden, damit nicht jeder Einkauf nachverfolgt werden kann. Außerdem ist das ökologischer.": "I try to avoid shopping online so that not every purchase can be tracked. On top of that, it’s better for the environment.",
        "Ich kaufe nur noch über das Internet und sehe auch keine Alternative für die Zukunft.": "I only buy online, and I don’t see any future alternative.",
        "Ich kaufe bevorzugt bei ganz bestimmten Unternehmen ein, denen ich mein Vertrauen schenke. Wenn es sich vermeiden lässt, verzichte ich auf den Kauf bei Großkonzernen, die meine Daten sammeln.": "I prefer shopping online at certain companies I trust. If possible, I avoid shopping at big companies that collect my data.", 

        /* 15 */

        "Würdest du eine Dating-App benutzen?": "Would you use a dating app?",

        "Wenn ich auf der Partnersuche wäre, würde ich die bekanntesten Dienste nutzen und alle Informationen preisgeben, um die besten Matches für mich finden zu können.": "If I were looking for a partner, I would use the most popular services and reveal all my information to be able to find the best match.",
        "Ich würde grundsätzlich keine Dating-App benutzen.": "No, I would never use a dating app.",
        "Ich verzichte auf eine Dating-App aus Datenschutzgründen.": "No, I refuse to use dating apps for privacy reasons.",
        "Ich würde sie benutzen, aber versuchen, möglichst wenig Informationen preiszugeben und auch nur für kurze Zeit.": "I would use one, but only for a short time and I would try to reveal as little information as possible.",
        "Ja, denn bei einer Dating-App, auf der man nur drei Bilder hochlädt, gibt man nicht zu viele Informationen über sich preis.": "Yes, I would. You won’t be revealing that much personal data when you upload only three images.",
        "Wenn ich Single und auf Partnersuche wäre, dann wäre mir jedes Mittel recht.": "If I were single and searching for a partner, any means would do.",


        /* Fragen mit Auswertung */
        "Klicke auf einen Datenschutztyp, um zu sehen, welche Antwort ihm entspricht.": "Click on a data protection type to see which answer corresponds to it.",

        /* Ergebnis #1 */
        "Ergebnis": "Result",
        "Du gehörst zu": "You belong to",
        "% der Gruppe der": "% to the group of",
        " an.": ".",

        /* Ergebnis #1 - Refl. Datenschuetzer */
        "Datenschutz ist mir sehr wichtig! Dafür bin ich gerne bereit auf bestimmte Dinge zu verzichten.": "Data protection is very important to me! For that, I’m happy to do without certain tools and applications if necessary.",
        "Du kennst dich mit Datenschutz schon sehr gut aus und verstehst, dass du über dein Surfverhalten und deine Gerätenutzung bestimmen kannst, wer Zugriff auf deine persönlichen Daten erhält. Den damit stellenweise verbundenen Verzicht nimmst du in Kauf, denn so bewahrst du dir deine digitale Souveränität.": "You already know a lot about data protection and you understand that you can determine who has access to your personal data through your surfing behaviour and device use. You are willing to accept the sacrafices that this entails in some cases because it means you will be preserving your digital sovereignty.",

        /* Ergebnis #1 - Enthusiast */
        "Ich probiere alle neuen digitalen Produkte und Dienste aus! Datenschutz kommt erst an zweiter Stelle.": "I like trying every new digital product and service offered! Data protection comes second.",
        "Du bist gegenüber technischer Innovation sehr aufgeschlossen und probierst gerne Neues aus. Um neue Geräte und Anwendungen vollumfänglich nutzen zu können, bist du auch bereit, deine persönlichen Daten als Währung zu verwenden.": "You embrace technical innovation and like trying out new things. In exchange, your openness to using these new applications to their full extent, means that you are also willing to use your personal data as currency.",

        /* Ergebnis #1 - Teilzeit */
        "Ich versuche, meine Daten zu schützen, aber auf manche Dienste möchte ich einfach nicht verzichten.": "I try to protect my data, but I can’t do without the usage of some services.",
        "Dir ist bewusst, dass Datenschutz ein wichtiges Thema ist, und du verstehst, dass du durch dein Verhalten aktiv steuern kannst, wer Zugriff auf deine persönlichen Daten erhält. Wenn du aber eine bestimmte Anwendung unbedingt nutzen willst, zum Beispiel, weil sie dir den Alltag erleichtert, bist du auch bereit, deine Daten als Währung zu akzeptieren.": "You are aware that data protection is an important issue and you understand that you can actively control who has access to your personal data by regulating your behaviour. However, if you really want to use a certain application, for example because it makes your everyday life easier, you are also willing to accept your data as currency.",

        /* Ergebnis #1 - Egalo */
        "Wenn eine Anwendung deinen Alltag erleichtern kann oder einfach Spaß macht, nutzt du sie. Die Diskussion über Datenschutz kannst du dabei nicht nachvollziehen. Du hast zwar mitbekommen, dass Digitalkonzerne viele Daten sammeln und damit Geld verdienen, du verstehst aber nicht so ganz, warum das ein Problem sein soll.": "If an application can make your everyday life easier or is enjoyable, you use it. You don’t really understand the fuss about data protection. You’re aware that digital companies collect and store your data, and also earn a lot of money with it, but you don’t quite understand why that should be a problem.",

        /* Ergebnis #1 - Unwissender */
        "Das Internet und digitale Anwendungen bieten dir viele Vorteile und machen dein Leben einfach bequemer. Das möchtest du uneingeschränkt nutzen und dabei ist dir egal, ob jemand deine Bequemlichkeit ausnutzen könnte. Du hast zwar schon einmal gehört, dass Datenschutz ein wichtiges Thema sein soll, weißt aber eigentlich gar nicht so recht, um was es da genau gehen soll.": "The Internet and digital applications offer you many advantages and simplify your life. You want to take full advantage of this, and you don't care if someone takes advantage of your desire for convenience. You‘ve heard about the importance of data protection, but you don’t really understand what it’s about.",
        

        /* Ergebnis #2 */
        "Hier siehst du, zu wie viel Prozent deine Antworten den verschiedenen Datenschutztypen entsprechen." : "Here you can see the percentage by which your answers correspond to the different types of data protection.",
        "Deine ursprüngliche Auswahl": "Your initial selection",


        /* Ergebnis #3 */

        /* Ergebnis #3 - Refl. Datenschuetzer */
        "Herzlichen Glückwunsch! Du bist beim Thema Datenschutz sehr engagiert und kennst dich gut aus. Wir empfehlen dir - Bleib‘ am Ball! Vielleicht interessieren dich die folgenden Angebote, die dich in Sachen Datenschutz immer auf dem Laufenden halten.": "Congratulations! You are very committed to the topic of data protection and know your stuff. We recommend you stay on the ball! You may be interested in the following information services, which will help you keep up to date on data protection and privacy matters.",

        "TIPP 1 ) Auch wenn du dazu bereit bist, muss Datenschutz nicht unbedingt Verzicht bedeuten. Für viele digitale Anwendungen, Tools und Services gibt es auch Alternativen, die ethische Facetten berücksichtigen. Sicherlich sind dir viele bereits bekannt, aber vielleicht ist dir auch noch die eine oder andere Alternative entgangen. Eine umfangreiche Sammlung vom datenschutzfreundlichen Web-Browser bis hin zum fairen Smartphone findest du unter ": "TIP 1 ) Even if you are willing to do so, data protection does not necessarily have to mean doing without. For many digital applications, tools and services, there are also alternatives that take ethical facets into account. You are probably already familiar with many of them, but perhaps one or two alternatives have escaped your attention. You can find a comprehensive collection at ",
        "TIPP 2 ) Die neuesten Meldungen, Publikationen und Projekte zum Thema Datenschutz und Digitale Ethik kannst du auf dem 'Mastodon'-Account des Instituts für Digitale Ethik verfolgen. Aufrufbar unter ": "TIP 2 ) You can follow the latest news, publications and projects on data protection and digital ethics on the 'Mastodon' account of the Institute for Digital Ethics. Accessible at ",

        /* Ergebnis #3 - Teilzeit */
        "Gratulation! Das Thema Datenschutz liegt dir am Herzen. Dennoch fühlst du dich manchmal hin- und hergerissen, insbesondere, wenn du dich trotz einiger Zweifel ab und zu auch gegen den Datenschutz entscheidest. Vielleicht helfen dir die folgenden Tipps und Informationen dabei, dich im Umgang mit deinen Daten und dem Schutz deiner Privatsphäre noch sicherer fühlen zu können. Damit kannst du auch vor deinen Freundinnen und Freunden und deinem Umfeld den Mehrwert des Datenschutzes deutlicher erkennen und benennen.": "Congratulations! The issue of data privacy is close to your heart. Nevertheless, you sometimes feel torn, especially when you decide against data protection from time to time despite your doubts. Perhaps the following information will help you to feel even more confident in handling your data and protecting your privacy. It might also help you to clearly recognize and identify the value of data protection when discussing with friends and family.",

        "TIPP 1 ) Eine einfache Möglichkeit selbst in Sachen Datenschutz tätig zu werden bietet das 'Digital Detox Kit'. Umfangreiche Tipps und praktische Anleitungen zu Fragen wie du deine Smartphone-Daten kontrollierst oder dich von unliebsamen Standarteinstellungen befreist findest du unter ": "TIP 1 ) The 'Digital Detox Kit' offers a simple way to take action on data protection. Extensive tips and practical instructions e.g., on how to control your smartphone data or get rid of unwanted default settings can be found at ",
        "TIPP 2 ) Datenschutz muss nicht unbedingt Verzicht bedeuten. Für viele digitale Anwendungen, Tools und Services gibt es auch Alternativen, die ethische Facetten berücksichtigen. Eine umfangreiche Sammlung vom datenschutzfreundlichen Web-Browser bis hin zum fairen Smartphone findest du unter ": "TIP 2 ) Data protection does not necessarily have to mean doing without. For many digital applications, tools and services, there are also alternatives that take ethical facets into account. You can find a comprehensive collection at ",

        /* Ergebnis #3 - Enthusiast */
        "Du möchtest deine Begeisterung für die Digitalisierung nicht durch Datenschutz schmälern lassen. Du befürchtest, dass Datenschutz eher ein Innovationshindernis ist? Wir empfehlen dir daher die folgenden Links, um einen neuen Blick auf das Thema Datenschutz zu werfen.": "You don’t want to let data protection and privacy curb your enthusiasm for digitalization. Are you afraid that data protection hinders innovation? We therefore recommend the following links to get a fresh perspective on the topic of data protection.",

        "TIPP 1 ) Das Internet und die Digitalisierung schaffen unglaublich viele neue Möglichkeiten. In welchen Situationen es dabei trotzdem wichtig ist aufmerksam zu sein und sich und andere zu schützen, zeigen die '10 Gebote der Digitalen Ethik' anhand von konkreten Beispielen unter ": "TIP 1 ) The internet and digitalization create an incredible number of new opportunities. Utilizing concrete examples, the '10 commandments of digital ethics' show the situations in which it is nevertheless important to be attentive and to protect oneself and others. Available at ",
        "TIPP 2 ) Datenschutz muss nicht unbedingt Verzicht bedeuten. Für viele digitale Anwendungen, Tools und Services gibt es auch Alternativen, die ethische Facetten berücksichtigen. Eine umfangreiche Sammlung vom datenschutzfreundlichen Web-Browser bis hin zum fairen Smartphone findest du unter ": "TIP 2 ) Data protection does not necessarily have to mean doing without. For many digital applications, tools and services, there are also alternatives that take ethical facets into account. You can find a comprehensive collection at ",

        /* Ergebnis #3 - Egalo */
        "Du findest, das Thema Datenschutz wird unnötig hoch bewertet, und du zweifelst sogar manchmal am Sinn des Datenschutzes? Wir empfehlen dir daher die folgenden Berichte und Informationen, um einen neuen Blick auf die Bedeutung des Datenschutzes zu werfen.": "Do you believe that data protection is taken too seriously? We recommend that you take a look at the following reports and information to get a fresh perspective on the value and importance of data protection.",
 
        "TIPP 1 ) Das Projekt ‘Do Not Track’ bietet dir einen unkomplizierten Einstieg ins Thema und zeigt dir mit Hilfe von 7 kurzen und interaktiven Videoclips, warum Datenschutz auch für dich wichtig sein kann. Aufrufbar unter ": "TIP 1 ) The project 'Do Not Track' offers you an easy introduction to the topic and shows you with the help of 7 short and interactive video clips why data protection can also be important for you. Available at ",
        "TIPP 2 ) Ein guter Einstieg um aktiv tätig zu werden bietet der 'Digital Safety Compass' des Instituts für Digitale Ethik. Praktische Tipps zur digitalen Selbstverteidigung im Drehscheibenformat findest du unter ": "TIP 2 ) The 'Digital Safety Compass' of the Institute for Digital Ethics is a good starting point for taking action. Practical tips on digital self-defence in compass-format can be found at ",

        /* Ergebnis #3 - Unwissender */
        "Das Thema Datenschutz ist in deinen Augen sehr komplex und du hast manchmal das Gefühl, dir fehlen vielleicht die richtigen Informationen und das Wissen, um einen echten Zugang zu diesem Thema zu finden? Wir empfehlen dir daher die folgende Einführung.": "From your perspective, the topic of data protection is very complex and you sometimes have the feeling that you might be lacking the right information and knowledge to really approach this topic. We would suggest that you start with the following introduction to data protection.",
      
        "TIPP 1 ) Das Projekt ‘Do Not Track’ bietet dir einen unkomplizierten Einstieg ins Thema und zeigt dir mit Hilfe von 7 kurzen und interaktiven Videoclips, warum Datenschutz auch für dich wichtig sein kann. Aufrufbar unter ": "TIP 1 ) The project 'Do Not Track' offers you an easy introduction to the topic and shows you with the help of 7 short and interactive video clips why data protection can also be important for you. Available at ",
        "TIPP 2 ) Das Internet und die Digitalisierung schaffen unglaublich viele neue Möglichkeiten. In welchen Situationen es dabei trotzdem wichtig ist aufmerksam zu sein und sich und andere zu schützen, zeigen die '10 Gebote der Digitalen Ethik' anhand von konkreten Beispielen unter ": "TIP 2 ) The internet and digitalization create an incredible number of new opportunities. Utilizing concrete examples, the '10 commandments of digital ethics' show the situations in which it is nevertheless important to be attentive and to protect oneself and others. Available at ",


        /* Ergebnis #3 - wie gehts weiter? */

        "Fast geschafft! Hier findest du unsere abschließenden Empfehlungen für dich.": "Almost done! Here are our final recommendations for you.",
        "Am Ende der Seite erfährst du, wie es weiter geht.": "At the bottom of the page you'll find out what to do next.",
        "ENDE DER AUSWERTUNG": "END OF EVALUATION",
        "Toll gemacht! Du hast den Privat-o-Mat erfolgreich abgeschlossen.": "Well done! You successfully completed the 'Privat-o-Mat'.",

        "Wenn du Lust hast, noch mehr zum Thema Datenschutz zu lernen, klicke nun auf WEITER, um zu sehen, welche Antworten für welchen Datenschutztyp stehen. ": "If you want to learn even more about data protection, click on NEXT to see which answers represent which privacy persona. ",
        "Wenn du mit dem Privat-o-Mat noch einmal von vorne anfangen möchtest, klicke einfach im Menü auf HOME, um eine neue Session zu starten.": "If you want to restart the 'Privat-o-Mat', just click HOME in the menu to start a new session.",

      }
    }
  }

i18n.init({
  resources,
  lng: "de",
  debug: false,
})

export default i18n
