import React from "react"
import Modal from "react-bootstrap/Modal"

import template from "../logic/template"
import i18n from "../logic/translation"


function Impressumbox(props) {
  const [show, setShow] = React.useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      {props.type === "button" && (
        <button
          type="button"
          className="btn btn-light btn-md border border-dark text-uppercase"
          onClick={handleShow}
        >
          {i18n.t(template.infobox.buttonText)}
        </button>
      )}

      {props.type === "navLink" && (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="nav-link" id="impressumButton" href="#" onClick={handleShow}>
            {i18n.t("Impressum, Lizenzen")}<br></br>{i18n.t("& Datenschutz")}
          </a>
        </>
      )}

      <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{i18n.t(template.infobox.headingImpressum)}</Modal.Title>
        </Modal.Header>
        <Modal.Body><h2>{i18n.t(template.infobox.bodyHeadline4)}</h2><br></br><p>{i18n.t(template.infobox.bodyContent4)}</p><br></br>
                    <h5>{i18n.t(template.infobox.bodyHeadline5)}</h5>
                    <p>Hochschule der Medien (Institut für Digitale Ethik)<br></br>
                    Nobelstraße 10<br></br>
                    70569 Stuttgart<br></br>
                    Tel.: 0711-8923-10<br></br>
                    <a href="https://www.hdm-stuttgart.de/digitale-ethik" target="_blank">www.hdm-stuttgart.de/digitale-ethik</a>
                    <br></br><a href="mailto:info@privat-o-mat.de">info@privat-o-mat.de</a></p>
                    {i18n.t(template.infobox.rechtsPerson)}<br></br>USt.-ID-Nr.: DE 224 427 890<br></br>
                    <br></br><h5>{i18n.t(template.infobox.bodyHeadline6)}</h5>
                    <p>{i18n.t(template.infobox.leitungsgremium)}<br></br>
                    Prof. Dr. Petra Grimm<br></br>Prof. Dr. iur. Tobias Keber<br></br>Prof. Dr. Oliver Zöllner
                    </p>
                    <br></br><h5>{i18n.t(template.infobox.bodyHeadline7)}</h5>
                    <p>{i18n.t(template.infobox.studierende)}<br></br>
                    Elena Klotz, Florian Flatten, Alexander Jacob, Aldin Aslani, Hanna Machata<br></br><br></br>
                    {i18n.t(template.infobox.mitarbeiterund)}<br></br>
                    Michel Hohendanner, Susanne Kuhnert, Jan Doria, Marcel Schlegel, Karla Neef<br></br>Prof. Dr. Petra Grimm, Prof. Dr. iur. Tobias Keber, Prof. Dr. Oliver Zöllner<br></br><br></br>
                    {i18n.t(template.infobox.withthehelpof)}
                    </p>
                    <br></br><h5>{i18n.t(template.infobox.bodyHeadline8)}</h5>
                    <p>
                      Michel Hohendanner</p>
                    <br></br><h5>{i18n.t(template.infobox.bodyHeadline9)}</h5>
                    <p>Michel Hohendanner<br></br>
                    {i18n.t(template.infobox.withthehelpof)}
</p>
                    <br></br>
                    <h5>{i18n.t(template.infobox.bodyHeadline10)}</h5>
                    <p>Elena Klotz, Florian Flatten, Alexander Jacob, Aldin Aslani, Hanna Machata</p>
                    <br></br><hr className="impressumTrenner"></hr><br></br>
                    <h2>{i18n.t(template.infobox.headingLizenz)}</h2><br></br>
                    {i18n.t(template.infobox.bodyLizenz)}<br></br><a href="https://www.privat-o-mat.de/lizenzhinweise_dritter.txt" target="_blank">Download Lizenzhinweise_Dritter.txt</a><br></br><br></br>
                    
                    <br></br><hr className="impressumTrenner"></hr><br></br>
                    <h2>{i18n.t(template.infobox.headingDatenschutz)}</h2><br></br>
                    {i18n.t(template.infobox.bodyDatenschutz)}<br></br><a href="https://www.hdm-stuttgart.de/datenschutz" target="_blank">Zu den Datenschutzbestimmungen der Hochschule der Medien Stuttgart</a><br></br><br></br>
                    
                    </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light btn-md border border-dark text-uppercase"
            onClick={handleClose}
          >
            {i18n.t("Schliessen")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Impressumbox
