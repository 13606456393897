import React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { ermittleAvatar, ermittleName } from "../logic/persona"
import i18n from "../logic/translation"

function Comic(props) {
  /* Selbsteinschaetzung */

  if (props.type === "selbsteinschaetzung") {
    return (
      <div
        onClick={props.isClickDisabled ? undefined : (e) => props.onClick(e)}
        id={"item_" + props.persona}
        className={props.className + (props.isClickDisabled ? " cursor-normal" : " cursor-pointer")}
      >
        <div>
          <img src={ermittleAvatar(props.persona)} width={200} alt="" />
        </div>
        <hr />
        <div className="selbsteinschaetzungText">„{props.content}“</div>
      </div>
    )
  }

  /* Fragen */
  if (props.type === "fragen") {
    return (
      <div
        onClick={props.isClickDisabled ? undefined : (e) => props.onClick(e)}
        id={"item_" + props.position}
        className={props.isClickDisabled ? " cursor-normal" : " cursor-pointer"}
      >
        <div
          className={"antwort" + (props.isClickDisabled ? " cursorDefault" : "") + props.className}
        >
          {props.content}
        </div>
      </div>
    )
  }

  /* Fragen mit Auswertung */
  if (props.type === "auswertung") {
    return (
      <div
        onClick={props.isClickDisabled ? undefined : (e) => props.onClick(e)}
        id={"item_" + props.position}
        className="cursor-pointer"
      >
        <div>
          <OverlayTrigger overlay={<Tooltip>{i18n.t(ermittleName(props.persona))}</Tooltip>}>
            <span className="d-inline-block">
              <img src={ermittleAvatar(props.persona)} width={60} alt="" />
            </span>
          </OverlayTrigger>
        </div>
      </div>
    )
  }

  /* Ergebnis #1 */
  if (props.type === "ergebnis1") {
    return (
      <div>
        <img src={ermittleAvatar(props.persona)} width={400} alt="" className={props.className + " img-fluid"} />
      </div>
    )
  }

  /* Ergebnis #2 */
  if (props.type === "ergebnis2") {
    return (
      <div>
        <img src={ermittleAvatar(props.persona)} width={100} alt="" className={props.className + " img-fluid"} />
      </div>
    )
  }
}

export default Comic
